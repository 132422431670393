// reference for Mobile Device Detection - npm install react-device-detect --save
//https://github.com/duskload/react-device-detect#readme 
// reference for tabs - https://www.npmjs.com/package/react-tabs
// import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Image from "./Image";
// import {barcode} from '../imagesbase'
// import barcodeimg from './barcode.jpg'
import { Col, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { SEARCH_MODES, SEARCH_TEXT } from "./constants/constants";
import "./bootstrap.min.css";
import "./design.css";
import Form from "./Form";
import  FunctionalEquivalent  from "./FunctionalEquivalent";
import Navbar from "./navbar";
import PartList from "./PartList";
import ProductList from "./ProductList";
import ProductResults from "./ProductResults";
import SerialNumberBOM from "./SerialNumberBOM";
import { getQueryParam } from "./Util";
import { isMobile } from 'react-device-detect';
// Tab Components - Start
// import { Tabs, Tab } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// Tab Components - End
import { SERIALWITHPRODUCT } from './constants/APIConstants';
import { getAuthHeader } from './Util';
import axios from 'axios';
import Loading from './Loading';
import Details from './Details';
//import { green } from "@material-ui/core/colors";
// Root PartSurfer Component.

  
class PartSurfer extends React.Component {
  
  state = {
    dataLoading: false,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectValue: "Please Select a Product Number",
      ProdListError: "",
      dataLoading: false
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }
  handleDropdownChange(e) {
    this.setState({ selectValue: e.target.value });
  }
  
  onSubmit = () => {
    this.setState({ dataLoading: true })
    const searchText = getQueryParam(this.props, SEARCH_TEXT);
    const { selectValue } = this.state;
    if(!selectValue.includes("Select"))
    {
      let url;
      if(selectValue.includes("#"))
      {
        let textval;
        textval = selectValue.slice(0, selectValue.indexOf("#"));
        url = SERIALWITHPRODUCT(searchText,textval,'US',localStorage.getItem("usertype"))
      }
      else
      {
        url = SERIALWITHPRODUCT(searchText,selectValue,'US',localStorage.getItem("usertype"))
      }
      axios.get(url, {
        headers: {
          ...getAuthHeader()
        }
      })
        .then((response) => {
          this.loadDetails(response.data.Body ? response.data.Body : null, "swp")
          this.setState({ dataLoading: false, selectValue: "Please Select a Product Number" })
        }, (error) => {
          this.setState({ dataLoading: false, selectValue: "Please Select a Product Number" })
        })
    }
    else
    {
      this.setState({dataLoading: false, ProdListError: "Please select any product number!" })
    }
  }
  

  loadDetails = (data, mode) => {
    if (!data && !mode) {
      this.setState({
        partResults: undefined,
        productResults: undefined,
        SNResults: undefined,
        wildCharResults: undefined,
        FEResults: undefined,
        SNRProductListsResults: undefined,
        dataLoaded: false,
      });
      return;
    }
    // console.log('loading data', data);
    switch (mode) {
      case SEARCH_MODES.PART:
        this.setState({ partResults: data, dataLoaded: true });
        break;
      case SEARCH_MODES.PRODUCT:
        this.setState({ productResults: data, dataLoaded: true });
        break;
      case SEARCH_MODES.SEARIAL_NUMBER:
        this.setState({ SNResults: data, dataLoaded: true });
        break;
      case SEARCH_MODES.FUNCTIONAL_EQUIVALENT:
        this.setState({ FEResults: data, dataLoaded: true });
        break;
      case SEARCH_MODES.SERIAL_WP:
        let rest = data || {};
        this.setState({
          productResults: rest.ProductBOM,
          SNResults: rest.SerialNumberBOM,
          SNRProductListsResults: rest.SNRProductLists,
          dataLoaded: true,
        });
        break;
      default:
        let res = data || {};
        this.setState({
          partResults: res.Part,
          productResults: res.ProductBOM,
          SNResults: res.SerialNumberBOM,
          wildCharResults: res.ProductNameList,
          SNRProductListsResults: res.SNRProductLists,
          dataLoaded: true,
        });
    }
    // this.setState({ data })
  };

  render() {
    const {
      productResults,
      partResults,
      SNResults,
      wildCharResults,
      SNRProductListsResults,
      FEResults,
      dataLoaded,
    } = this.state;

    const searchText = getQueryParam(this.props, SEARCH_TEXT);
    let tab1 = "General";
    let tab2 = "Advanced";
    let tab1gen = "General(Product Family)";
    let tab2adv = "Advanced(Unique Product S/N)";
    let tabgen1;
    if(isMobile)
    {
      tabgen1 = tab1;
    } 
    else{ tabgen1 = tab1gen;}
    let tabadv1;
    if(isMobile)
    {
      tabadv1 = tab2;
    } 
    else{ tabadv1 = tab2adv;}
    let noData =
      dataLoaded &&
      (productResults || []).length === 0 &&
      !partResults &&
      (SNResults || []).length === 0 &&
      (wildCharResults || []).length === 0 &&
      (FEResults || []).length === 0 &&
      (SNRProductListsResults || []).length === 0;
    const {  dataLoading } = this.state;
    return (
      <React.Fragment>
        <Navbar />
        <div style={{ backgroundColor: "white" }} className="container-fluid">
          <div className="container">
            <Form onSubmit={this.loadDetails} />
            <br />
            {!searchText && (
              <React.Fragment>
                <p>
                  Do not know your part number? You can find it by entering one
                  of the following values from your product's service tag.
                </p>
                <Row>
                  <Col xs={"12"} sm={"8"} md={"5"} lg={"5"} xl={"4"}>
                    <Image src='barcode.jpg'/>
                  </Col>
                  <Col xs={"12"} sm={"12"} md={"12"} lg={"7"} xl={"8"}>
                    &nbsp; <b>Details</b>: <br />
                    &nbsp;1. Product name: This is the product name affixed to
                    the front of the device.
                    <br />
                    &nbsp;2. Serial number (s/n): This is an alphanumeric
                    identifier that is unique to each product.
                    <br />
                    &nbsp;3. Part number/Product number (p/n): This number
                    provides specific information about the product’s hardware
                    components. The part number helps a service technician to
                    determine what components and parts are needed.
                    <br />
                    &nbsp;4. Warranty period: The number describes the duration
                    of warranty period for the device.
                    <br />
                    &nbsp;5. Model description (selected models only): This
                    Alphanumeric identifier used to locate documents, drivers
                    and support for the device.
                    <br />
                  </Col>
                </Row>
                <br />
                <br />
                <div>
                  More information on how to find your HP Part / Product /
                  Serial Number{" "}
                  <a href="https://support.hp.com/us-en">
                    click here
                  </a>
                </div>
              </React.Fragment>
            )}

            {
              <React.Fragment>
                <Loading loading={dataLoading} >
                <div>
                
                      {(SNRProductListsResults || []).length > 0 && (
                        <div>
                          <div>
                            <div>
                              <b> Serial Number : {searchText} </b>
                                <br />                              
                            </div>
                            <div style={{color: "#3FE827"}}>
                              Multiple Products associated for above Serial Number.
                                <br />
                                <br />
                            </div>
                          </div>
                          <select name="productsnrlists" className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" onChange={this.handleDropdownChange} >  
                          <option>Please Select a Product Number</option>  
                          {(this.state.SNRProductListsResults || []).map((e, key) => {  
                                  return <option key={key} value={e.product_Id}>{e.product_Id} - {e.product_Desc}</option>;  
                          })}  
                          </select>
                          <br />
                            <div>
                              <button type="button" onClick={this.onSubmit} className="apply btn btn-primary">Submit</button>
                            </div>
                            <br />
                           {this.state.ProdListError && <div style={{color: "#E9573F"}}>{this.state.ProdListError}</div>
                           }
                        </div>
                      )}
                </div>
                </Loading>
                <div>
                      {(wildCharResults || []).length > 0 && (
                        <ProductList data={wildCharResults} />
                      )}
                </div>
                {!SNResults && dataLoaded && !noData && !SNRProductListsResults && !wildCharResults &&
                <div>
                   {(productResults || []).length > 0 && (
                    <div><Details mode='PRODUCT' payload={productResults} /> <br/></div>
                  )}                 
                    {/* <Tabs defaultActiveKey="General" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="General" title="General(Product Family)">
                          {partResults && <PartList data={[partResults]} />}
                          {(FEResults || []).length > 0 && (
                            <FunctionalEquivalent data={FEResults} />
                          )}
                          {(productResults || []).length > 0 && (
                            <ProductResults data={productResults} />
                          )}
                    </Tab>
                  </Tabs> */}
                  <Tabs>
                        <TabList>
                          <Tab>{tabgen1}</Tab>
                          {/* <Tab>Advanced(Unique Product S/N)</Tab> */}
                        </TabList>
                        <TabPanel>
                          {partResults && <PartList data={[partResults]} />}
                            {(FEResults || []).length > 0 && (
                              <FunctionalEquivalent data={FEResults} />
                            )}
                            {(productResults || []).length > 0 && (
                              <ProductResults data={productResults} />
                            )}
                        </TabPanel>
                        {/* <TabPanel>
                          <h2>Any content 2</h2>
                        </TabPanel> */}
                  </Tabs>
                </div>
              } 
              {SNResults &&
              <div>
                <Details mode='SERIAL' payload={SNResults} />
                <br /> 
                   <Tabs defaultIndex={1}>
                      <TabList>
                      {(productResults || []).length > 0 && (
                          <Tab>{tabgen1}</Tab>
                          )}   
                        <Tab>{tabadv1}</Tab>
                      </TabList>
                      {(productResults || []).length > 0 && (
                        <TabPanel>
                          <ProductResults data={productResults} />
                        </TabPanel>
                      )} 
                      <TabPanel>
                        <SerialNumberBOM data={SNResults} />
                      </TabPanel>
                    </Tabs>
               </div> 
              }   
                                
                {noData && (
                  <div>
                    <div className="alert alert-primary " role="alert">
                      No additional information found in PartSurfer. Please read
                      the hints below, and try again.{" "}
                    </div>
                    <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">
                        Hints For Using PartSurfer:-
                      </h4>
                      <p>
                        The most reliable and fastest way to identify your
                        product is to enter the serial number or product number
                        from the service tag (such as CNU123456789 or RU477EA )
                        in the search box above, but a more general description
                        of the product (such as LaserJet 8100 or Pavilion 6532)
                        is also acceptable. Also, if you know the HP part number
                        you are looking for (such as 8120-6260 or CB394-40014M),
                        you may enter that directly.
                      </p>
                      <hr />
                      <p className="mb-0">
                        Searching for an HP part by entering a general
                        description (such as Power Cord, Fusing Assembly,
                        Motherboard, or Cartridge) will not be successful. After
                        first identifying your HP product (as described above),
                        you will be given several options for finding the part
                        that you need.
                      </p>
                      <p>
                        You may also{" "}
                        <a
                          href="https://partsurfer.hp.com/Search.aspx"
                          target="_PartSurfer"
                        >
                          click here
                        </a>{" "}
                        to return to the main PartSurfer screen and select from
                        the list of HP product families. Additional information
                        can be found in the HP PartSurfer User Guide .
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            }
          </div>
          <br />
          <br />
          <br />
          <div className="col-md-12 text-md-center">
            <Link style={{ color: "#003366" }}  to="/PartSurfer/ContactUS">
              <u>Feedback to HP PartSurfer</u>
            </Link>
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PartSurfer);