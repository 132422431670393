import React from "react";
import { ViewHOC } from "./ViewHOC";
import "./bootstrap.min.css";
import "./design.css";
import './main.css';
import Navbar from "./navbar";
import { Link } from "react-router-dom";
import { getURLQueryString, getAuthHeader } from './Util';
import { GENERATEREPORT } from './constants/APIConstants';
import axios from 'axios';
import { getLocationObject } from "./Util";
import secureLocalStorage from "react-secure-storage";

class GenerateMetrics extends React.Component {
  constructor(props) {
    super(props);    this.state = {
      modalnumber: '', 
      data: '',
    };
  }
  componentDidMount() {
    axios.post(GENERATEREPORT(secureLocalStorage.getItem("loggeduser")), {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        const dt = res.data.Body;
        this.setState({ data: res.data.Body })
      }, (error) => {
        this.setState({ data: null })
      })

  }
  render() {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "white" }} className="container-fluid">
          <Navbar />
          <div><br/>
              <div>
                      <div className="alert alert-success" role="alert">
                          <p>
                            Monthly Metrics Process initiated in Backend. You will receive monthly metrics report on your Mail.
                            <br/>
                            Please write the Email to PartSurfer@hp.com if you not received within 2 Hours.
                          </p>
              </div>
            </div>
          </div>          
          </div>
          <br />
      <div style={{display: 'flex', justifyContent: 'left'}}>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;
            <Link className="apply btn btn-primary" to={getLocationObject()}>
              Back to home
            </Link>
        </div>
        <br/>
        <br/>
        <br/>
      </div>
      </React.Fragment>
    );
  }
}
  
  export default ViewHOC(GenerateMetrics);