import { getStoredCart, setStoredCart } from '../CartUtils';

const cart = (state = { cartItems: getStoredCart() }, action ) => {
  switch (action.type) {
    case "SET_CART_ITEMS":
        console.log("cart-item",action.payload)
        setStoredCart(action.payload)
      return {
        ...state,
        cartItems: action.payload,
      };
    default:
        return state;
}
}
export default cart;
