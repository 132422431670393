import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import ContactUS from './components/ContactUS';
import Faq from './components/Faq';
import PartSurfer from './components/PartSurfer';
import UserGuide from './components/UserGuide';
import Hierarchy from './components/Hierarchy';
import NewRequest from './components/NewRequest';
import GenerateMetrics from './components/GenerateMetrics';
import Sbom from './components/Sbom';
import GoToTop from './components/GoToTop';
import CallBack from './components/CallBack';
import cors from "cors";
import { generateToken } from './components/Util';



class App extends React.Component {
  
  componentDidMount() {
    let tokenexptime = `${process.env.REACT_APP_TOKEN_REG}`
    generateToken();
    setInterval(generateToken, tokenexptime*1000);
  }

  render() {
    // setInterval(() => {//This function is called as per set interval to regenerate Token 
    //   generateToken(); //just before it expires
    // }, process.env.REACT_APP_TOKEN_REG*1000);//Time in MS
    return (
      <React.Fragment>
        <Helmet
          title="HP PartSurfer">
            {
            process.env.REACT_APP_HEADER_LINK ? <script
              src={process.env.REACT_APP_HEADER_LINK}
              type="text/javascript"
            /> : undefined
          }

        </Helmet>
        <Switch>
          <Route path="/PartSurfer/Faq" component={Faq}/>
          <Route path="/PartSurfer/UserGuide" component={UserGuide}/>
          <Route exact path="/" component={PartSurfer} />
          <Route  path="/PartSurfer/ContactUS" component={ContactUS}/>
          <Route  path="/PartSurfer/Hierarchy" component={Hierarchy}/>
          <Route  path="/PartSurfer/NewRequest" component={NewRequest}/>
          <Route  path="/PartSurfer/GenerateMetrics" component={GenerateMetrics}/>
          <Route  path="/PartSurfer/Sbom/:value" component={Sbom}/>
          <Route exact path="/PartSurfer/CallBack/" component={CallBack} />
        </Switch>

        <GoToTop/>{/* Added by Shreyas N for GO To Top functionality. */}

      </React.Fragment>
    );
  }
}

export default App;
