import React, { Component } from "react";
import SpareBOM from "./SpareBOM";
import { Accordion, Card } from "react-bootstrap";
import ComponentBOM from "./ComponentBOM";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import Details from "./Details";
import { isObject } from "lodash";
import { ViewHOC } from "./ViewHOC";
import secureLocalStorage from "react-secure-storage";

const initialFilters = () => ({
  category: [],
  techCourier: [],
  rohs: [],
  CSR: [],
});

class SerialNumberBOM extends Component {
  
  constructor(props) {
    super(props);
    
    // console.log('consruct', props)
    this.state = {
      COMPONENT_collapsed: false,
      SPARE_collapsed: false,
      filters: initialFilters(),
      filteredData: [],
      metadata: {
        category: [],
        techCourier: [],
        rohs: [],
        CSR: [],
      },
    };
  }

  componentDidMount() {
    this.filterData(); //TODO: no option will be there
    this.generateMetdata();
  }
  // componentDidMount() {
  //   console.log("as", this.props);
  // }

  generateMetdata = () => {
    const { filters } = this.state;
    const { data } = this.props;

    let category = [];
    let techCourier = [];
    let rohs = [];
    let CSR = [];
    let count = 0;

    let categoryFilters = filters.category || [];
    let techCourierFilters = filters.techCourier || [];
    let rohsFilters = filters.rohs || [];
    let CSRFilters = filters.CSR || [];

    (data.spare_part || [])
      .filter(
        (product) =>
          (techCourierFilters.length === 0 ||
            techCourierFilters.indexOf(product.sp_techcourier) !== -1) &&
          (categoryFilters.length === 0 ||
            categoryFilters.indexOf(product.sp_category) !== -1) &&
          (rohsFilters.length === 0 ||
            rohsFilters.indexOf(product.sp_rohs_status_code) !== -1) &&
          (CSRFilters.length === 0 ||
            CSRFilters.indexOf(product.sp_csrflag) !== -1)
      )
      .forEach((part) => {
        const {
          sp_category,
          sp_techcourier,
          sp_rohs_status_code,
          sp_csrflag,
        } = part;
        count++;
        category =
          category.indexOf(sp_category) !== -1
            ? category
            : [...category, sp_category];
        techCourier =
          techCourier.indexOf(sp_techcourier) !== -1
            ? techCourier
            : [...techCourier, sp_techcourier];
        rohs =
          rohs.indexOf(sp_rohs_status_code) !== -1
            ? rohs
            : [...rohs, sp_rohs_status_code];
        CSR = CSR.indexOf(sp_csrflag) !== -1 ? CSR : [...CSR, sp_csrflag];
     //   console.log("spare", category, techCourier, rohs, CSR);
      });
    this.setState({
      metadata: { category, techCourier, rohs, CSR },
      itemsCount: count,
    });
  };

  filterData = () => {
    const { filters } = this.state;
    const { data } = this.props;
    const { category, techCourier, rohs, CSR } = filters;
    let categoryFilters = category || [];
    let techCourierFilters = techCourier || [];
    let rohsFilters = rohs || [];
    let CSRFilters = CSR || [];
    const res = (data.spare_part || []).filter(
      (product) =>
        (techCourierFilters.length === 0 ||
          techCourierFilters.indexOf(product.sp_techcourier) !== -1) &&
        (categoryFilters.length === 0 ||
          categoryFilters.indexOf(product.sp_category) !== -1) &&
        (rohsFilters.length === 0 ||
          rohsFilters.indexOf(product.sp_rohs_status_code) !== -1) &&
        (CSRFilters.length === 0 ||
          CSRFilters.indexOf(product.sp_csrflag) !== -1)
    );
    console.log("res", res);
    this.setState({ filteredData: res });
  };

  enableSearchResult=()=>{
    const { data } = this.props;
    return(
        (data.spare_part || []).filter((product)=>product.part_number==='-N/A-').length>0)
  }

  changeFilters = (key, value, isMulti = false, isFlag = false) => {
    const { filters } = this.state;
    if (isFlag) {
      this.setState({ filters: { ...filters, [key]: value } }, () =>
        this.generateMetdata()
      );
    } else if (isMulti) {
      this.setState({
        filters: {
          ...filters,
          [key]: (value || []).map((selection) => selection.value),
        },
      }); //, () => this.generateMetdata())
    } else {
      this.setState(
        {
          filters: {
            ...filters,
            [key]: value ? (isObject(value) ? value.value : value) : "",
          },
        },
        () => this.generateMetdata()
      );
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  sett = () => {
    this.setState({ COMPONENT_collapsed: !this.state.COMPONENT_collapsed });
  };

 
  render() {
    const { data,mobileView } = this.props;
    const { filters, itemsCount, filteredData } = this.state;
    const { unit_configuration, spare_part } = this.props.data;
    const { COMPONENT_collapsed, SPARE_collapsed } = this.state;


    return (
    
      <div>
        {/* <Details mode="SERIAL" payload={data} /> */}
        <br />

        {unit_configuration && (
          <Accordion defaultActiveKey={"COMPONENT"}>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={"COMPONENT"}
                onClick={() => {
                  this.setState({ COMPONENT_collapsed: !COMPONENT_collapsed });
                }}
              >
                <span>
                  {COMPONENT_collapsed ? (
                    <FontAwesomeIcon icon="chevron-up" />
                  ) : (
                    <FontAwesomeIcon icon="chevron-down" />
                  )}
                </span>
                &nbsp;&nbsp;Component BOM (OEM S/N BOM)
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={"COMPONENT"}>
                <Card.Body style={{ overflow: "auto" }}>
                  <ComponentBOM data={unit_configuration} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
        <br />
        <br />
        {spare_part && (
          <Accordion defaultActiveKey={"SPARE"}>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={"SPARE"}
                onClick={() => {
                  this.setState({ SPARE_collapsed: !SPARE_collapsed });
                }}
              >
                <span>
                  {SPARE_collapsed ? (
                    <FontAwesomeIcon icon="chevron-up" />
                  ) : (
                    <FontAwesomeIcon icon="chevron-down" />
                  )}
                </span>
                &nbsp;&nbsp;Spare BOM (HP Orderable Product BOM)
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={"SPARE"}>
                <Card.Body style={{ overflow: "auto" }}>
                  { mobileView ? (
                    <React.Fragment>
                      <Accordion defaultActiveKey="1">
                      {this.enableSearchResult()? (
                          null
                          ) : (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="0">
                            Click here to customize your search results
                            <div>
                              {itemsCount ? (
                                <div>{itemsCount} Records found</div>
                              ) : (
                                "no"
                              )}
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <Form>
                                  <Row>
                                      <Col>
                                    <Form.Group as={Row}
                                      controlId="formHorizontalCat"
                                    > 
                                      <Form.Label column md={'5'} lg={'4'} xl={'3'}>
                                        Category:
                                      </Form.Label>
                                      <Col  md={'12'} lg={'8'} xl={'8'}>
                                      <Select
                                          menuPortalTarget={document.body}
                                          // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          isMulti
                                          value={(filters?.category || []).map(
                                            (ele) => ({
                                              value: ele,
                                              label: ele,
                                            })
                                          )}
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "category",
                                              option,
                                              true
                                            )
                                          }
                                          options={(
                                            this.state.metadata?.category || []
                                          ).map((key) => ({
                                            value: key,
                                            label: key,
                                          }))}
                                        />
                                      </Col>
                                    </Form.Group>
                                    </Col>
                                    </Row>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalCSR"
                                    >
                                      <Form.Label column md={'5'} lg={'4'} xl={'3'}>
                                        CSR:
                                      </Form.Label>
                                      <Col md={'12'} lg={'8'} xl={'8'}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          value={(filters?.CSR || []).map(
                                            (ele) => ({
                                              value: ele,
                                              label: ele,
                                            })
                                          )}
                                          isMulti={true}
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "CSR",
                                              option,
                                              true
                                            )
                                          }
                                          options={
                                            (
                                              this.state.metadata?.CSR || []
                                            ).map((key) => ({
                                              value: key,
                                              label: key,
                                            })) || []
                                          }
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalRohs"
                                    >
                                      <Form.Label column md={'5'} lg={'4'} xl={'3'}>
                                        ROHS:
                                      </Form.Label>
                                      <Col md={'12'} lg={'8'} xl={'8'}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "rohs",
                                              option,
                                              true
                                            )
                                          }
                                          isMulti={true}
                                          value={(filters?.rohs || []).map(
                                            (ele) => ({
                                              value: ele,
                                              label: ele,
                                            })
                                          )}
                                          options={(
                                            this.state.metadata?.rohs || []
                                          ).map((key) => ({
                                            value: key,
                                            label: key,
                                          }))}
                                        />
                                      </Col>
                                    </Form.Group>
                                    {secureLocalStorage.getItem("loggeduser") != null ?
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalTech"
                                    >
                                      
                                      <Form.Label column md={'5'} lg={'4'} xl={'3'}>
                                        Tech Courier:
                                      </Form.Label>
                                      <Col md={'12'} lg={'8'} xl={'8'}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          // Value={['-N/A-']}
                                          value={(
                                            filters?.techCourier || []
                                          ).map((ele) => ({
                                            value: ele,
                                            label: ele,
                                          }))}
                                          isMulti={true}
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "techCourier",
                                              option,
                                              true
                                            )
                                          }
                                          options={
                                            (
                                              this.state.metadata
                                                ?.techCourier || []
                                            ).map((key) => ({
                                              value: key,
                                              label: key,
                                            })) || []
                                          }
                                        />
                                      </Col>                                      
                                    </Form.Group>: "" }
                                    <div
                                      className="searchbox"
                                      style={{ float: "right" }}
                                    >
                                      <button
                                        type="button"
                                        className="apply btn btn-primary"
                                        onClick={() => {
                                          this.filterData();
                                        }}
                                      >
                                        Apply
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        type="button"
                                        className="reset btn btn-primary"
                                        onClick={() => {
                                          this.setState(
                                            { filters: initialFilters() },
                                            () => {
                                              this.generateMetdata();
                                              this.filterData();
                                            }
                                          );
                                        }}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                </Form>
                                </Card.Body>
                                </Accordion.Collapse>
                                </Card>)}
                                </Accordion> 
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Accordion defaultActiveKey="1">
                        {this.enableSearchResult()? (
                          null
                          ) : (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="0">
                            Click here to customize your search results
                            <div style={{ float: "right" }}>
                              {itemsCount ? (
                                <div>{itemsCount} Records found</div>
                              ) : (
                                "no"
                              )}
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <Form>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalCat"
                                    >
                                      <Form.Label
                                        column
                                        md={"2"}
                                        lg={"3"}
                                        xl={"3"}
                                      >
                                        Category:
                                      </Form.Label>
                                      <Col md={"6"} lg={"6"} xl={"6"}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          isMulti
                                          value={(filters?.category || []).map(
                                            (ele) => ({
                                              value: ele,
                                              label: ele,
                                            })
                                          )}
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "category",
                                              option,
                                              true
                                            )
                                          }
                                          options={(
                                            this.state.metadata?.category || []
                                          ).map((key) => ({
                                            value: key,
                                            label: key,
                                          }))}
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalCSR"
                                    >
                                      <Form.Label
                                        column
                                        md={"2"}
                                        lg={"3"}
                                        xl={"3"}
                                      >
                                        CSR:
                                      </Form.Label>
                                      <Col md={"6"} lg={"6"} xl={"6"}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          // Value={['-N/A-']}
                                          value={(filters?.CSR || []).map(
                                            (ele) => ({
                                              value: ele,
                                              label: ele,
                                            })
                                          )}
                                          isMulti={true}
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "CSR",
                                              option,
                                              true
                                            )
                                          }
                                          options={
                                            (
                                              this.state.metadata?.CSR || []
                                            ).map((key) => ({
                                              value: key,
                                              label: key,
                                            })) || []
                                          }
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalRohs"
                                    >
                                      <Form.Label
                                        column
                                        md={"2"}
                                        lg={"3"}
                                        xl={"3"}
                                      >
                                        ROHS:
                                      </Form.Label>
                                      <Col md={"6"} lg={"6"} xl={"6"}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "rohs",
                                              option,
                                              true
                                            )
                                          }
                                          isMulti={true}
                                          value={(filters?.rohs || []).map(
                                            (ele) => ({
                                              value: ele,
                                              label: ele,
                                            })
                                          )}
                                          options={(
                                            this.state.metadata?.rohs || []
                                          ).map((key) => ({
                                            value: key,
                                            label: key,
                                          }))}
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                  {secureLocalStorage.getItem("loggeduser") != null ?
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalTech"
                                    >
                                      <Form.Label
                                        column
                                        md={"2"}
                                        lg={"3"}
                                        xl={"3"}
                                      >
                                        Tech Courier:
                                      </Form.Label>
                                      <Col md={"6"} lg={"6"} xl={"6"}>
                                        <Select
                                          menuPortalTarget={document.body}
                                          closeMenuOnSelect={false}
                                          onMenuClose={this.generateMetdata}
                                          isSearchable
                                          // Value={['-N/A-']}
                                          value={(
                                            filters?.techCourier || []
                                          ).map((ele) => ({
                                            value: ele,
                                            label: ele,
                                          }))}
                                          isMulti={true}
                                          onChange={(option) =>
                                            this.changeFilters(
                                              "techCourier",
                                              option,
                                              true
                                            )
                                          }
                                          options={
                                            (
                                              this.state.metadata
                                                ?.techCourier || []
                                            ).map((key) => ({
                                              value: key,
                                              label: key,
                                            })) || []
                                          }
                                        />
                                      </Col>
                                    </Form.Group> : "" }
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div
                                      className="searchbox"
                                      style={{ float: "right" }}
                                    >
                                      <button
                                        type="button"
                                        className="apply btn btn-primary"
                                        onClick={() => {
                                          this.filterData();
                                        }}
                                      >
                                        Apply
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        type="button"
                                        className="reset btn btn-primary"
                                        onClick={() => {
                                          this.setState(
                                            { filters: initialFilters() },
                                            () => {
                                              this.generateMetdata();
                                              this.filterData();
                                            }
                                          );
                                        }}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                     
                         )}
                                      </Accordion>
                    </React.Fragment>
                  )}
                  <br />
                  <SpareBOM data={filteredData || []} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </div>
    );
  }
}
export default ViewHOC(SerialNumberBOM);