import React from 'react'

export default function Image({src}) {
    return (
        
            <img className="responsive"
                      src={`${window.location.origin}/img/${src}`}
                      alt="img"
                      onError={(e) => {
                        e.target.src = `${window.location.origin}/PartSurfer/img/${src}`; //replacement image imported above
                      }}
                    />
     
    )
}
