import React from 'react';
import { GETGLBURL } from './constants/APIConstants';
import { ViewHOC } from "./ViewHOC";
import {getURLQueryString } from './Util';
import secureLocalStorage from 'react-secure-storage';


class login extends React.Component {
    state = {
      countryLoading: true,
      User :'',
    };
    
    componentDidMount() {
       let username = secureLocalStorage.getItem("loggeduser");
       if(username!=null)
       {
          if(username!="UID_Failed")
          {
                secureLocalStorage.setItem("loggeduser",username);
                this.setState({ User: secureLocalStorage.getItem("loggeduser") });
          }
      }
    }
    
    removeCookie = (e) => {
      e.preventDefault();
      secureLocalStorage.removeItem("loggeduser");
      this.setState({ User: null });
      alert("LogOut Success!");
      window.location.href = "/";
    }
  
    
    render() {
      const { User } = this.state;      
      return (      
        <React.Fragment>
                <div style={{ float: 'right', color: "#003366", width:"auto" }} >
                    {User} {User ?
                    <a
                    href="/"
                    rel="noopener noreferrer"
                    style={{ color: "#003366" }}
                    onClick={this.removeCookie}
                    >
                    &nbsp;&nbsp;<b>LogOut</b>
                    </a> : 
                      <a
                        href={GETGLBURL}
                        style={{ color: "#003366" }}
                        title="HP Internal Users purpose Only."
                      >
                      <b>Login</b>
                      </a> 
                    }                    
                </div>
                <div><br/><br/></div>
        </React.Fragment>         
      );
    }
  }
  
  export default ViewHOC(login)
  