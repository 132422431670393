import React from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';// Added By Shreyas for local storage security.
import { Button,Tooltip, OverlayTrigger} from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
// import {FaArrowCircleUp} from 'react-icons/fa';
import "./design.css";
import { getAuthHeader } from './Util';
import { PRODUCTLIST } from './constants/APIConstants';
import ProductResults from "./ProductResults";
import { ViewHOC } from './ViewHOC';
import Loading from "./Loading";


class DescProductListPrint extends React.Component {
    constructor() {
        super()
        this.state = {
          isBack: false,
          parts: [],
          loading: false,
          visible:false,          
            }
            this.myRef=React.createRef();
        }
        // componentWillUnmount()
        // {
        //   window.addEventListener('scroll', this.toggleVisible.bind(this),true);
        // }
        // componentDidMount()
        // {
        //   window.addEventListener('scroll', this.toggleVisible.bind(this),true);
        // }
        //  toggleVisible() {
        //   //  console.log("inside toggle");
        //   const scrolled = document.documentElement.scrollTop;
        //   if (scrolled > 300){
        //     // console.log("page scrolled");
        //     this.setState({visible:true}) 
        //   } 
        //   else if (scrolled <= 300){
        //     this.setState({visible:false}) 
        //   }
        // };
        //  scrollToTop = () =>{
        //   //  console.log("inside scrollToTop");
        //   window.scrollTo({
        //     top: 0, 
        //     behavior: 'smooth'
        //   });
        //   // this.myRef.current.scrollIntoView({
        //   //   behavior:"smooth"
        //   // })
        // }; 
        BackToPreviousPage =  ()=>{ 
         this.setState({loading:true}) 
         let prodNumber = this.props.data[0].ProductNumber   
         const url = PRODUCTLIST(prodNumber,localStorage.getItem("usertype"))
         axios.get(url, {
          headers: {
            ...getAuthHeader()
          }
        })
          .then((response) => {
             //console.log('call success data:', response.data.Body );    
           this.setState({parts: response.data.Body })  
           this.setState({isBack:true,
            loading:false,
          });              
          }, (error) => {
          })
      }

    render()
    {
        const { data,mobileView } = this.props; 
        const { isBack,loading,visible } = this.state;  
       // console.log("state value", data)  
        // console.log("state value", this.state.parts)     
        const columns =  mobileView        
         ? [
          {
            text: "#",
            dataField: '',
            headerAttrs: {
              hidden: true,
            },
            formatter: (col, prod, index) => {
              return (
                <React.Fragment key={prod.PartNumber}>
                  <b>Part Number&nbsp;:&nbsp;</b>
                    {prod.PartNumber}
                  <br />
                  <b>Description&nbsp;:&nbsp;</b>
                  <div dangerouslySetInnerHTML={{ __html: prod.EnhancedDescription }}></div>
                  <b>CSR&nbsp;:&nbsp;</b>
                  {prod.CsrFlag}
                  <br />
                  <b>ROHS&nbsp;:&nbsp;</b>
                  {prod.RohsCompliant}
                  <br />
                </React.Fragment>
              );
            },
          },
        ]
         : [ {
                dataField: "PartNumber",
                text: "Part Number",
                headerStyle: () => {
                  return { width: "6%" };
                },
              },
              {
                dataField: "EnhancedDescription",
                text: "Part Description ",
                formatter: (col) => (
                  <div dangerouslySetInnerHTML={{ __html: col }}></div>
                ),
                headerStyle: () => {
                  return { width: "35%" };
                },
              },
              {
                dataField: "CsrFlag",
                text: "CSR ",
                headerStyle: () => {
                  return { width: "1%" };
                },
              },
              secureLocalStorage.getItem("loggeduser") == null ? {
                dataField: "TechCourier",
                text: "Tech Courier",
                hidden: true,
                headerStyle: () => {
                  return { width: "3%" };
                },
              } : {
                dataField: "TechCourier",
                text: "Tech Courier",
                headerStyle: () => {
                  return { width: "3%" };
                },
              },
              {
                dataField: "RohsCompliant",
                text: "ROHS",
                headerStyle: () => {
                  return { width: "2%" };
                },
              },
            ]
            // window.addEventListener('scroll', this.toggleVisible.bind(this),true);
              return (    
                   isBack?(
                    <ProductResults data={this.state.parts}/>
                        )
                        :
                        <div  >
                          {/* ref={this.myRef} */}
                        <Loading loading={loading}>
                           </Loading>
                <React.Fragment >
               <div className="btn-print-friendly">
                  <Button className="apply btn btn-primary" onClick={() => this.BackToPreviousPage()} >Normal View</Button> 
                </div>
                <br/>
                <BootstrapTable
                keyField="PartNumber"
                data={data}
                columns={columns}
                striped
              />
              
              {/* <div > 
              <OverlayTrigger
                      placement = "left"
                      overlay = {<Tooltip>Go To Top</Tooltip>}>  
              <FaArrowCircleUp className='go-to-top-button' onClick={this.scrollToTop.bind(this)} 
              style={{display: visible ? 'inline' : 'none'}}     
              />
              </OverlayTrigger>
             </div> */}
               </React.Fragment>
               </div>
            )        
    }
}
export default (ViewHOC(DescProductListPrint));