import React from "react";
import { ViewHOC } from "./ViewHOC";
import { Alert, Card, Container, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Login from "./login";
import { getLocationObject } from "./Util";
import Navbar from "./navbar";
import "./bootstrap.min.css";
import "./design.css";
import './main.css';

function Faq() {
  return (
    <React.Fragment> 
      <div>  
         <Navbar />    
        <div style={{ marginRight: "10px"}}>
          <br/>
          <Login />
          <br/>
        </div>
      </div>
      <Container>
        <Alert className="theme">
          <p>
            This document answers some of the common questions regarding HP
            PartSurfer and Service Parts Information (SPI) data. If you have a
            question or concern that is not addressed here, please refer to our{" "}
            <Link to="/PartSurfer/ContactUS">contact page</Link> for more
            detailed help or to contact us via email.
          </p>
        </Alert>

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Questions and Answers ...
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <b>Access to HP PartSurfer</b>
                <br />
                <b>
                  Q.Do I have to register in order to use HP PartSurfer?
                </b>{" "}
                <br />
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b> A.</b>HP PartSurfer does NOT require registration. Before
                  you can access data in PartSurfer, you will be asked to select
                  from the list of over 40 countries and regions for which we
                  provide part information. Your selection will be saved in a
                  cookie on your computer, and you will automatically be
                  provided with the correct part information on future visits.{" "}
                </p>
                <b>Q.Is there a cost for using HP PartSurfer?</b>
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b style={{ fontsize: "14px" }}>A.</b>We are happy to provide
                  PartSurfer at no cost to assist customers in identifying and
                  purchasing genuine HP parts.
                </p>
                <b>Q.Why won't HP PartSurfer run on my computer?</b>
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b style={{ fontsize: "14px" }}>A.</b>You must be running
                  version 4 or greater of either Netscape Navigator or Internet
                  Explorer to use PartSurfer. Cookies must be allowed, and
                  JavaScript must be enabled.
                  If this does not address your problem, please refer to our{" "}
                  <Link to="/PartSurfer/ContactUS">contact page</Link> for more
                  detailed help or to contact us via email.
                </p>
                <br />
                <b>General Application Questions</b>
                <br />
                <b>Q.How often is HP PartSurfer updated?</b>{" "}
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b style={{ fontsize: "14px" }}>A.</b>HP PartSurfer is updated
                  from the main SPI database every Monday through Friday at
                  approximately 6 PM Pacific time.
                </p>
                <b>
                  Q.I thought HP PartSurfer displayed part pricing information.
                  Why do I not see any part prices?
                </b>
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b style={{ fontsize: "14px" }}>A.</b>Part prices are no
                  longer displayed in PartSurfer for some countries. Web-based
                  applications and other resources for ordering HP parts on-line
                  are now available in most geographies. These interfaces
                  provide access to 'real-time' part prices, as well as dealer
                  and volume discounts, and information on parts unique to your
                  country or region.
                  <a
                    href="https://parts.hp.com/Hpparts/CountryChoice.aspx"
                    target="buyparts"
                    className="udrline"
                  >
                    Click here
                  </a>{" "}
                  for information on accessing HP part prices and buying HP
                  parts in your area.
                </p>
                <br/>
                <b>Part/Product and Ordering Questions</b>
                <br />
                <b>
                  Q.I have found the part that I need. Now how do I order it?
                </b>
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b style={{ fontsize: "14px" }}>A.</b>Based on the country
                  selection chosen when you first use the site, we will detect
                  whether a parts is orderable from the HP Parts Store or not.
                  If a box appears next to the part then click on it to{" "}
                  <b>[Add to Cart]</b>. If there is NO box then it may not be
                  orderable. You can also at any time go directly to the HP
                  Parts Store (under the <b>Services</b> menu above) and double
                  check at any time. When your selections are completed, click
                  on the <b>Shopping Cart</b> icon and follow the steps to check
                  availability, part price, and other ordering options available
                  to you in your country.
                </p>
                <b>
                  Q.Why can't I find the part or product that I need on HP
                  PartSurfer? What do I do now?
                </b>{" "}
                <p
                  style={{
                    fontfamily: "HPSimplified",
                    fontsize: "12px",
                  }}
                >
                  <b style={{ fontsize: "14px" }}>A.</b>HP PartSurfer is
                  intended to identify products and associated parts with the
                  highest volume of parts information requests and provide
                  high-quality data for them, not to provide a 100% solution for
                  all HP products and parts. Some HP products are set up on our
                  centralized repair program, and have few user-replaceable
                  parts. This could be the reason that the part you are looking
                  for is not on HP PartSurfer. For further assistance with
                  service parts information, or to determine the best repair
                  strategy for your HP product, please refer to the resources
                  listed on our{" "}
                  <Link to="/PartSurfer/ContactUS">contact page.</Link>
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
      </Container>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div>
            <Link className="apply btn btn-primary" to={getLocationObject()}>
              Back to home
            </Link>
        </div>
        <br/>
        <br/>
        <br/>
      </div>
    </React.Fragment>
  );
}

export default ViewHOC(Faq);
