import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ViewHOC } from "./ViewHOC";


class FunctionalEquivalent extends Component {

    render() {

        const { data, mobileView } = this.props;
       
        const columns = [
            {
                dataField: 'PartNumber',
                text: 'Part Number',
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            /* {
                dataField: 'Keyword',
                text: 'Keyword ',
                headerStyle: () => {
                    return { width: "auto" };
                }
            }, */
            {
                dataField: 'EnhancedDescription',
                text: 'Part Description ',
                formatter: (col) => <div dangerouslySetInnerHTML={{ __html: col }}></div>,
                headerStyle: () => {
                    return { width: "auto" };
                }
            },
            {
                dataField: 'CSRFlag',
                text: 'CSR ',
                headerStyle: () => {
                    return { width: "5%" };
                }
            },
            localStorage.getItem("loggeduser") == null ? {
                dataField: 'TechCourier',
                text: 'Tech Courier',
                headerStyle: () => {
                    return { width: "10%" };
                },
                hidden: true,
            }: {
                dataField: 'TechCourier',
                text: 'Tech Courier',
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'ROHSCompliant',
                text: 'ROHS ',
                headerStyle: () => {
                    return { width: "20%" };
                }
            }];

        return (
            <React.Fragment>
                {mobileView
                    ? <React.Fragment>
                       
                        {/* <hr /> */}
                        {
                            (data || []).map(prod => <React.Fragment>
                                <b>Part Number&nbsp;:&nbsp;</b>{prod.PartNumber}
                                <br />
                                {/* <b>Keyword&nbsp;:&nbsp;</b>{prod.Keyword}
                                <br /> */}
                                <b>Part Description&nbsp;:&nbsp;</b>
                                {/* {prod.EnhancedDescription}  */}
                                <div dangerouslySetInnerHTML={{ __html: prod.EnhancedDescription }}></div>
                                <br />
                                <b>CSR&nbsp;:&nbsp;</b>{prod.CsrFlag?prod.CsrFlag:"-N/A-"}
                                <br />
                                <b>Tech Courier&nbsp;:&nbsp;</b>{prod.TechCourier?prod.TechCourier:"-N/A-"}
                                <br />
                                <b>ROHS&nbsp;:&nbsp;</b>{prod.RohsCompliant?prod.RohsCompliant:"-N/A-"}
                                <br />
                                {/* <hr /> */}
                                {/* <hr /> */}
                            </React.Fragment>)
                        }
                    </React.Fragment>
                    : <BootstrapTable keyField='PartNumber' data={data} columns={columns} striped pagination={data.length > 10 ? paginationFactory() : undefined} />
                }
            </React.Fragment>
        )
    }
}

export default (ViewHOC(FunctionalEquivalent));



