import React from "react";
import {
  Accordion, Alert,
  Card,
  Col,
  Container,
  ListGroup,
  Nav,
  Row,
  Tab
} from "react-bootstrap";
import FE_User from "../FE_User.png";
import go from "../go .gif";
import SBOM_NEW from "../SBOM_NEW.png";
import Shopping_cart from "../Shopping_cart.png";
import Show_fun_eq from "../Show_fun_eq.png";
import "./design.css";
import "./UserGuide.css";
import { ViewHOC } from "./ViewHOC";
import Navbar from "./navbar";
import Login from "./login";
import { Link } from "react-router-dom";

function UserGuide() {
  return (
    
    <React.Fragment>
      <div>
      <Navbar />
        <div style={{ marginRight: "10px"}}>
          <br/>
          <Login />
          <br/>
        </div>
    </div>
      <Container>
        <Alert className="theme">
          <p>
            <b>Before you continue reading this document</b>, we hope that you
            will try using HP PartSurfer first. PartSurfer is designed to be
            very user friendly, and most users find it easy to locate the parts
            information they need without detailed instructions. Simply move
            your mouse over any field heading in italics for help on getting
            started. This user guide is provided for those who prefer more
            detailed step-by-step instructions.
          </p>
        </Alert>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Purpose and Scope</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Basic Tasks</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">System Requirements</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">
                    Font Conventions Used in This Document
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Card>
                      <ListGroup variant="flush" style={{ fontSize: "16px" }}>
                        <ListGroup.Item>
                          HP PartSurfer is designed to help HP product owners
                          and HP product repair technicians identify part
                          numbers and other part information, primarily for the
                          purpose of servicing and repairing HP products.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          We also provide a direct link to the HP Parts Store
                          (for U.S. customers only) to allow you to order parts
                          online.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          PartSurfer does NOT include instructions for repair,
                          maintenance, or installation of products and parts.{" "}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          If you are not trained in repairing your product, or
                          are not certain that you know what the exact problem
                          is with your product, we strongly encourage you to
                          first contact HP technical support to determine the
                          best repair strategy for your HP product.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          (
                          <b>
                          <Link to="/PartSurfer/ContactUS">Contact Us</Link>
                          </b>{" "}
                          for information on product support options.)
                        </ListGroup.Item>
                      </ListGroup>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Card>
                      <ListGroup variant="flush" style={{ fontSize: "16px" }}>
                        <ListGroup.Item>
                          PartSurfer assists with two basic types of part lookup
                          tasks, with a variety of options for getting to the
                          information that you need:
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          You may need to find or verify a part number and other
                          part information for an HP part, based primarily on
                          the part's physical description or its function within
                          your product.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          You may have the part in front of you, may have a
                          description of the part from someone else, or may know
                          from your repair experience what part you need.{" "}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          For whatever reason, you need to find the HP part
                          number and its associated information, and possibly
                          order the part
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          You may know the part number for the HP part that you
                          need, and simply need to look up part information
                          and/or order the part.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          This feature of PartSurfer also provides a list of all
                          HP products that use a specific part.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <b>Please note:</b> the part number of the part
                          currently recommended by HP for replacement may not be
                          the same as the number that you see on the old part,
                          or even in your product documentation, as part numbers
                          are sometimes changed as different versions of a part
                          become available.{" "}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          You should only use this direct part lookup feature if
                          you are very sure that you know the part number that
                          you need.
                        </ListGroup.Item>
                      </ListGroup>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Card>
                      <ListGroup variant="flush" style={{ fontSize: "16px" }}>
                        <ListGroup.Item>
                          Microsoft Edge 90.x or greater, Chrome 20.x
                          or greater, and Firefox version 6.x or greater.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          Cookies must be allowed (see browser’s help file).
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          JavaScript must be enabled (see browser’s help file).{" "}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          Browser pop-up blocker must be off for some functions
                          to work (see browser’s help file)
                        </ListGroup.Item>
                      </ListGroup>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Card>
                      <ListGroup variant="flush" style={{ fontSize: "16px" }}>
                        <ListGroup.Item>
                          Specific actions that you can take within PartSurfer,
                          such as <b>Enter a Model Number</b> or
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <b>Select a Keyword</b> are displayed in a <b>bold</b>{" "}
                          font for quick reference.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          Screen field headings, such as Part Number or product
                          search: are displayed in italics.{" "}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          Examples of information to be entered by the user,
                          such as 33440-69001 or laserjet 5 are displayed in a
                          typewriter font.
                        </ListGroup.Item>
                      </ListGroup>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Finding an HP Part Number
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Follow these step-by-step instructions to find an HP
                        part number and associated information:
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div id="accordion" className="panel-group">
                          <div className="panel">
                            <div className="panel-heading">
                              <h6 className="panel-title">
                                <a
                                  href="#panelBodyOne"
                                  className="accordion-toggle"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                >
                                <p> &nbsp;&nbsp;Select your country or region . . .</p>
                                </a>
                              </h6>
                            </div>
                            <div
                              id="panelBodyOne"
                              className="panel-collapse collapse in"
                            >
                              <div className="panel-body">
                                <p>
                                  Before using HP PartSurfer, you first need to
                                  let us know what country or region you are in.
                                  You will only need to do this once, and it
                                  will be remembered in a cookie on your
                                  computer for all your future accesses. Some of
                                  our information is tailored to specific
                                  geographic areas. Just{" "}
                                  <b>
                                    select a country or region from the
                                    drop-down menu at the upper right of the
                                    main screen
                                  </b>
                                  . If your country isn't listed, select a
                                  nearby country, as information will probably
                                  be similar.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a
                                  href="#panelBodyTwo"
                                  className="accordion-toggle collapsed"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                >
                                 <p>&nbsp;&nbsp; Identify your HP product . . .</p>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="panelBodyTwo"
                              className="panel-collapse collapse"
                            >
                              <div className="panel-body">
                                <p>
                                  We next need to know what HP product you have
                                  in order to help you find the part(s) that you
                                  need. After FIRST locating your HP product, as
                                  described below, you will be given several
                                  options for finding your part(s). There are
                                  five different ways to find your product:
                                </p>
                                <b>
                                  Enter the serial number from your product in
                                  the text box located in the Quick Search tab.
                                </b>{" "}
                                The serial number is found on the back or bottom
                                of your HP product, and will be in a format such
                                as USE646N6FJ, 2UA73310K5 or CNU6080B8C.
                                <center>
                                  <b>OR</b>
                                </center>
                                <b>
                                  Enter the product number from your product in
                                  the text box located in the Quick Search tab.
                                </b>{" "}
                                The product number is found on the back or
                                bottom of your HP product, and will be in a
                                format such as C2010A, 33440A or D7399A. If you
                                can take a moment to find the product number on
                                your product, this will be the most accurate way
                                to locate the information you need.
                                <center>
                                  <b>OR</b>
                                </center>
                                <b>
                                  Enter the part number from your product in the
                                  text box located in the Quick Search tab.
                                </b>{" "}
                                The part number is found on the back or bottom
                                of your HP product, and will be in a format such
                                as C2801-40001, 384705-051 or 5063-1256.
                                <center>
                                  <b>OR</b>
                                </center>
                                <b>
                                  Enter the name or description of your product
                                  in the text box located in the Quick Search
                                  tab.
                                </b>{" "}
                                For example, laserjet 5, pavilion 6532 or
                                omnibook. Searching for an HP part by entering a
                                general description (such as Power Cord, Fusing
                                Assembly, Motherboard, or Cartridge) in the
                                search field on this screen will probably NOT be
                                successful.
                                <center>
                                  <b>OR</b>
                                </center>
                                <b>
                                  Select the general family that your product
                                  belongs to from the list on the Hierarchy tab.
                                </b>{" "}
                                Locate your product by 'drilling down' through
                                the HP product families.
                                <center>
                                  <b>OR</b>
                                </center>
                                <b>
                                  Enter the part/product number in the text box
                                  located in the Quick Search tab with wildcard
                                  [asterisk(*)] search for single character
                                  replacement.
                                </b>{" "}
                                For example, search for av*33u* which returns
                                matching results.
                                <p>
                                  <b>
                                    Select the Lookup similar items check box if
                                    you want to match similar part numbers or
                                    product numbers.
                                  </b>{" "}
                                  For example, if you enter the search string
                                  33440A and check the box, the following
                                  results will be returned: 33440A, 33440A-T,
                                  33440AB, etc. <b>Please note:</b> the Lookup
                                  similar items check box only works when
                                  searching for product numbers and part
                                  numbers.
                                </p>
                                <p>
                                  If your product search finds a single matching
                                  HP product, a screen will be displayed that
                                  will allow you to look up parts information.
                                  If your search finds multiple matches (or if
                                  you searched through product families), you
                                  will need to{" "}
                                  <b>select from a list of products</b> for that
                                  family or search result.
                                </p>
                                <p>
                                  The General Tab is the default view for most
                                  search results. This provides the user with a
                                  generic list of parts that are used within a
                                  family of similar products (multiple hard
                                  drives, memory modules, etc.) If a serial
                                  number search is performed, or a product SKU
                                  (Stock Keeping Number) search and you then
                                  click on the Advanced Tab, a more specific
                                  spares list called a BOM (Bill Of Materials)
                                  will be displayed when available. Also a
                                  Component BOM, which is a list of parts as
                                  used in the manufacturing process, may be
                                  available.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a
                                  href="#panelBodyThree"
                                  className="accordion-toggle collapsed"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                >
                                <p>&nbsp;&nbsp;Customize your search results . . .</p>  
                                </a>
                              </h4>
                            </div>
                            <div
                              id="panelBodyThree"
                              className="panel-collapse collapse"
                            >
                              <div className="panel-body">
                                <p>
                                  Once you have specified your product, you will
                                  see a list of all replacement parts for the
                                  product, grouped by keyword and part number.
                                  If you want to filter the parts list, you can{" "}
                                  <b>
                                    click on the Customize your results link
                                  </b>
                                  . This will display a form that will allow you
                                  to filter the parts listing based on the
                                  following criteria:
                                </p>
                                <ul>
                                  <li>
                                    <b className="headings">Select RoHS.</b>{" "}
                                    This will display a list of all replacement
                                    parts that are RoHS compliant.
                                    <center>
                                      <b>OR</b>
                                    </center>
                                  </li>
                                  <li>
                                    <b className="headings">
                                      Select Part photo.
                                    </b>{" "}
                                    This will display a list of all replacement
                                    parts for the product that have a part photo
                                    available.
                                    <center>
                                      <b>OR</b>
                                    </center>
                                  </li>
                                  <li>
                                    <b className="headings">
                                      Select a Keyword.
                                    </b>{" "}
                                    This will display a list of parts for the
                                    keyword. Keywords are very specific
                                    groupings of parts, such as Button, PC
                                    Board, Roller, Power Cord.
                                    <center>
                                      <b>OR</b>
                                    </center>
                                  </li>
                                  <li>
                                    <b className="headings">
                                      Select a Category.
                                    </b>{" "}
                                    This will display a list of parts for the
                                    category, grouped by keyword and part
                                    number. Categories are more general
                                    groupings of parts, based on location within
                                    the product or functionality, such as Fusing
                                    Assembly Parts, External Case Parts, Drives
                                    and Controllers, Electronic Accessories.
                                    <center>
                                      <b>OR</b>
                                    </center>
                                  </li>
                                  <li>
                                    <b className="headings">
                                      Enter a search value in the String Search
                                      field.
                                    </b>{" "}
                                    This will search through all part numbers
                                    and part descriptions for the product, and
                                    display a list of all matching parts,
                                    grouped by keyword and part number.
                                  </li>
                                </ul>
                                <p>
                                  After you have selected your filter criteria,{" "}
                                  <b>click the Apply button</b>. This will
                                  display an updated parts list based on your
                                  filter criteria.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a
                                  href="#panelBodyFive"
                                  className="accordion-toggle collapsed"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                >
                                <p>&nbsp;&nbsp;Find your part(s) using a parts diagram . . .</p>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="panelBodyFive"
                              className="panel-collapse collapse"
                            >
                              <div className="panel-body">
                                <p>
                                  If an interactive parts diagram is available
                                  for your product, a selection field labeled
                                  View a parts diagram will be shown on the
                                  General tab. Parts diagrams allow you to
                                  visually confirm that you have found the
                                  correct part. You may move your mouse over
                                  objects on the interactive diagram for quick
                                  part information, or click on the object for
                                  more detail. Please note that parts that are
                                  shown in pale gray on some diagrams are not
                                  orderable parts, but are included on the
                                  diagram for reference only.
                                </p>
                                <p>
                                  To view the parts diagram,{" "}
                                  <b>select Interactive mode</b> for an
                                  interactive parts diagram,{" "}
                                  <b>or select Printable mode</b> if you want to
                                  print the diagram. <b>Select the component</b>{" "}
                                  of the part you want to view from the
                                  drop-down menu. <b>Click the search icon</b>
                                  <img
                                    src={go}
                                    width="20"
                                    height="15"
                                    alt="Search icon"
                                  />
                                  to view the parts diagram.
                                </p>
                                <b className="headings">
                                  Move your mouse over an object
                                </b>{" "}
                                to see a pop-up box displaying the keyword and
                                part number(s) corresponding to the part.
                                <br />
                                <b className="headings">
                                  Click on an object or keyword
                                </b>{" "}
                                for more detailed part information. Information
                                for the part(s) (in the same format as in a
                                parts listing) will be displayed, followed by a
                                redisplay of the diagram with a red arrow
                                pointing to the selected part.
                                <br />
                                The pop-up box displayed for some diagram
                                objects contains a link labeled &#8216;View More
                                Detail&#8217;. <br />
                                <b className="headings">
                                  Click the View More Detail link
                                </b>{" "}
                                to display a more detailed view of the part or
                                assembly.
                                <p>
                                  If a parts diagram for your part is not
                                  available within PartSurfer, one may be
                                  available at the HP Service Media Library.{" "}
                                  <b>Click the HP Service Media Library link</b>{" "}
                                  in the left menu to be taken to the HP Service
                                  Media Library search page.
                                </p>
                                <b className="headings">
                                  Find your part(s) or part information on a
                                  parts listing . . .
                                </b>
                                <p>
                                  Parts listings contain the following fields
                                  and features to allow you to verify part
                                  numbers, access part prices (in some
                                  countries), and order parts on-line (currently
                                  only for U.S. customers):
                                </p>
                                Parts diagram/photo indicator: A camera icon in
                                the left column of a parts listing entry
                                indicates that there is a diagram or photo
                                displaying that part.{" "}
                                <b>Click on the camera icon</b> to display
                                either a photograph of the part or a diagram
                                with a red arrow pointing to the part. (See the
                                previous section for details on using parts
                                diagrams.)
                                <br />
                                Add to Cart: (U.S. only) used to select parts
                                you want to order from the HP Parts Store web
                                site. (See details on ordering parts below.)
                                <br />
                                Click to Buy: A Buy button that takes you
                                directly to the HP Home and Home Office Store.
                                <br />
                                Part number: The HP part number.
                                <br />
                                Description: A detailed description for the
                                part, including information, where relevant, on
                                where and/or how the part is used within the
                                specific product. Although PartSurfer does not
                                show real-time part availability information,
                                there are notes in some descriptions to indicate
                                parts that are no longer available, or that have
                                special ordering requirements.
                                <br />
                                Show Functional Equivalent: Once a products
                                spares list is displayed, click the
                                <img
                                  src={Show_fun_eq}
                                  width="120"
                                  height="20"
                                  alt="Search icon"
                                />
                                button. If an equivalent part is available, a
                                <img
                                  src={FE_User}
                                  width="20"
                                  height="20"
                                  alt="Search icon"
                                />
                                button will be displayed next to a part number.
                                Click the
                                <img
                                  src={FE_User}
                                  width="20"
                                  height="20"
                                  alt="Search icon"
                                />
                                button to display a popup window with that
                                information. Otherwise there will be a NO
                                Functional Equivalent message displayed.
                                <br />
                                <br />
                                SBOM button (May also appear as Updated SBOM
                                Available ): Clicking on the
                                <img
                                  src={SBOM_NEW}
                                  width="40"
                                  height="17"
                                  alt="Search icon"
                                />
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                          <div className="panel-heading">
                              <h4 className="panel-title">
                                <a
                                  href="#panelBodySix"
                                  className="accordion-toggle collapsed"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                >
                                   <p>&nbsp;&nbsp;Order parts through the HP Parts Store
                                  (available in select countries only) . . .</p>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="panelBodySix"
                              className="panel-collapse collapse in"
                            >
                              <div className="panel-body">
                                <p>
                                  From any PartSurfer parts listing,{" "}
                                  <b>
                                    select the check box in the Add to Cart
                                    column, and click the Add Parts to Cart
                                    button
                                  </b>
                                  . When you have filled your cart with all of
                                  the parts you want to order,{" "}
                                  <b>
                                    click the Shopping Cart icon
                                    <img
                                      src={Shopping_cart}
                                      width="19"
                                      height="19"
                                      alt="Shopping cart icon"
                                    />
                                    at the top of the page
                                  </b>
                                  . We hope to have linkages soon to online HP
                                  parts ordering tools that are being developed
                                  for other countries.
                                </p>
                                <p>
                                  The following actions are available on the
                                  Shopping Cart page:
                                </p>
                                <b>Update the number in the Qty text box</b> to
                                change the number of parts you want to order.
                                <br />
                                <b>
                                  Select the Remove check box and then click the
                                  Update cart <span>&#62;&#62;</span> button
                                </b>{" "}
                                to remove parts from your shopping cart.
                                <br />
                                <b>Click the Update cart  <span>&#62;&#62;</span> button</b> to
                                complete any changes you have made to the parts
                                in your shopping cart.
                                <br />
                                <b>Click the Place order  <span>&#62;&#62;</span> button</b> to be
                                taken to the HP Home and Home Office Store where
                                you can order the parts in your cart.
                                <br />
                                <b>Click the Order more parts  <span>&#62;&#62;</span> button</b> to
                                return to the PartSurfer homepage to search for
                                additional parts.
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a
                                  href="#panelBodySeven"
                                  className="accordion-toggle collapsed"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                >
                                <p>&nbsp;&nbsp;abbreviation . . .</p>  
                                </a>
                              </h4>
                            </div>
                            <div
                              id="panelBodySeven"
                              className="panel-collapse collapse"
                            >
                              <div className="panel-body">
                                <p>
                                  Below are some of abbreviation in PartSurfer
                                </p>
                                <ul>
                                  <li>
                                    <b className="headings">RoHS.</b>{" "}
                                    Restriction of Hazardous Substances
                                  </li>
                                  <li>
                                    <b className="headings">FE.</b>{" "}
                                    Funcational Equivalent
                                  </li>
                                  <li>
                                    <b className="headings">CSR.</b>{" "}
                                    Customer Self Repair
                                  </li>
                                </ul>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </React.Fragment>
  );
}

export default ViewHOC(UserGuide);
