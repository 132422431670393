import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from "js-cookie";
import React, { Fragment } from "react";
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';// Added By Shreyas for local storage security.
// import ScrollUpButton from "react-scroll-up-button";
import FunctionalEquivalent from './FunctionalEquivalent';
import { setCookie, getQueryParam, getURLQueryString, getAuthHeader } from './Util';
import { FUNCTIONAL_LIST, FUNCTIONAL, INSERTPHOTOMETRIC, CARTMETRIC, BUYMETRIC } from './constants/APIConstants';
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { setCarItems } from "../Actions";
import buy from "../buy.gif";
// import {FaArrowCircleUp} from 'react-icons/fa';
import { getItemIndex } from "../CartUtils";
import PartImages from "./PartImages";
import { ViewHOC } from "./ViewHOC";
import Loading from "./Loading";
import imageexists from "image-exists";
import { Tabs, Tab } from 'react-bootstrap';

class DescProductList extends React.Component {
  constructor() {
    super();
    this.state = {
      cart: [],
      showModal: false,
      showModalFE: false,
      part_number: "",
      modifiedData: [],
      partFEData: [],
      Ishide: false,
      isLoad: false,
      FELoading: false,
      // visible:false, 
      //loading: false,
    };
    this.state = { FELoading: false }
    this.closeModal = this.closeModal.bind(this);
    this.PrintPhoto = this.PrintPhoto.bind(this);
    this.closeModalFE = this.closeModalFE.bind(this);
    this.AddPhotoMetric = this.AddPhotoMetric.bind(this);
    // this.myRef=React.createRef();
  }

  /* componentDidUpdate(prevProps, prevState){
   // console.log("inside will")
    this.fetchDetails()
      // check if dataSource state is still empty
      if (!prevState.modifiedData) {
           // Your networking code
           this.fetchDetails();
      }
  } */

  fetchDetails = () => {
    let prodNumber = this.props.data[0].ProductNumber
    let partNumbers = this.props.data.map(item => {
      return item.PartNumber
    })
    const url = FUNCTIONAL_LIST(prodNumber, localStorage.getItem("usertype"))
    axios.post(url, partNumbers, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        let newData = [];
        let originalData = res.data.Body;
        // console.log("oridata",originalData); 
        newData = this.props.data.map((item, i) => Object.assign({}, item, originalData[i]));
        //console.log("newData",newData);
        this.setState({ modifiedData: newData })
      }, (error) => {
        console.log(error);
      })
  }

  showModal = (partnumber) => {
    var photo_type = ".jpg";
    const imageName = partnumber + photo_type;
    const imageLocation = `${process.env.REACT_APP_IMAGE_PATH}`;
    const imagePath = imageLocation;
    //const imagePath=  imageLocation+imageName;
    this.CheckImageavailability(imageLocation + imageName);
    this.AddPhotoMetric(partnumber);
    this.setState({ showModal: true, imagePath, isLoad: true, part_number: partnumber, });
  };

  CheckImageavailability = (path) => {
    imageexists(path, function (exists) {
      if (exists) {
        localStorage.setItem("photoAvailable", "Yes");
      }
      else {
        localStorage.setItem("photoAvailable", "No");
      }

    });

  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  showModalFE = (partnumber) => {
    //this.setState({loading:true})
    this.setState({ FELoading: true });
    const url = FUNCTIONAL(partnumber, 'US', localStorage.getItem("usertype"))
    axios.get(url, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        // let partFEData = res.data.Body;
        this.setState({
          // loading:false,
          showModalFE: true,
          partFEData: res.data.Body
        });
        this.setState({ FELoading: false })
      }, (error) => {
        this.setState({ FELoading: false })
      })
    //<FunctionalEquivalent data = {partFEData}/>        
  };

  buylink = (url, PartNumber) => {
    console.log(url);
    let urlprod;
    urlprod = BUYMETRIC(PartNumber);
    axios.get(urlprod, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((response) => {
      }, (error) => {
      })
    return window.open(url, "_blank");
  }

  closeModalFE = () => {
    this.setState({ showModalFE: false });
  };

  AddPhotoMetric = (partnumber) => {
    let input = partnumber;
    let urlprod;
    urlprod = INSERTPHOTOMETRIC(input, localStorage.getItem("photoAvailable"));
    axios.get(urlprod, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  PrintPhoto = () => {
    let elem = document.getElementById("photoImg");
    var domClone = elem.cloneNode(true);
    var $printSection = document.getElementById("printSection");
    if (!$printSection) {
      $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    document.getElementById("printSection").remove();
    // Added by shreyas to remove the image after print
    // so that it takes new image selected from dropdown.

  };

  handleCartChange = (event, item) => {
    console.log("Cart ==> from props", this.props);
    const { cart } = this.props;
    console.log("hnadle change request", event, item, [...(cart || [])]);
    if (event) {
      alert(
        `One Item with PartNumber ${item.PartNumber} has been added to the Cart`
      );
      item.qty = 1;
      const updatedCart = [...(cart || []), item];
      console.log("add", updatedCart);
      this.props.setCarItems(updatedCart);
      let urlprod;
      urlprod = CARTMETRIC(item.PartNumber, 1, 1);
      axios.get(urlprod, {
        headers: {
          ...getAuthHeader()
        }
      })
        .then((response) => {
        }, (error) => {
        })
    }
    else {
      alert(
        `One Item with PartNumber ${item.PartNumber} has been removed from Cart`
      );
      const ind = getItemIndex(item.PartNumber, cart);
      if (ind > -1) {
        let newCart = cart || [];
        newCart.splice(ind, 1);
        console.log("remove", newCart);
        this.props.setCarItems(newCart);
      }
    }
  };

  handleLoader = (value) => {
    console.log("load value", value)
    this.setState({ isLoad: value })
  }
  //   handleLoader (value){
  //    console.log("load value", value)
  //    this.setState({isLoad : value})
  //  }

  getImage = (event) => {
    const value = event.target.value;
    const imageLocation = `${process.env.REACT_APP_IMAGE_PATH}`;
    var text = '';
    text = text + '<h5><span class=\"badge badge-secondary"\>' + this.state.part_number + '</span></h5>';
    text = text + '<img id="photoImg" class=\"responsive"\ src=' + imageLocation + value + ".jpg" + ' alt=\"img"\/>';
    document.getElementById('mydiv').innerHTML = text;
  }


  render() {

    const { data, mobileView, cartQty } = this.props;
    const { imagePath, modifiedData, showModal, showModalFE, partFEData, Ishide, isLoad, FELoading } = this.state;
    const columns = mobileView
      ? [
        {
          text: "#",
          dataField: '',
          headerAttrs: {
            hidden: true
          },
          formatExtraData: (partNumber) => {
            // console.log("formatter data", a, b, c);
            return cartQty[partNumber] ? true : false;
          },
          formatter: (col, prod, index, extra) => {
            const presentInCart = extra(prod.PartNumber);
            return (

              <React.Fragment key={prod.PartNumber}>
                <b>Photo&nbsp;:&nbsp;</b>
                {prod.PartPhotoFlag ? (
                  <FontAwesomeIcon
                    icon="camera"
                    onClick={() => this.showModal(prod.PartNumber)} style={{ cursor: 'pointer' }}
                  />) : "-N/A-"}
                <br />
                <b>Part Number&nbsp;:&nbsp;</b>
                {prod.PartNumber}
                <br />
                <b>Part Description&nbsp;:&nbsp;</b>
                <div dangerouslySetInnerHTML={{ __html: prod.EnhancedDescription }}></div>
                {/* {prod.EnhancedDescription} */}
                {/* <br /> */}
                <b>CSR&nbsp;:&nbsp;</b>
                {prod.CsrFlag ? prod.CsrFlag : "-N/A-"}
                <br />
                {secureLocalStorage.getItem("loggeduser") == null ? "" : <b>Tech Courier&nbsp;:&nbsp;</b>}
                {secureLocalStorage.getItem("loggeduser") == null ? "" : prod.TechCourier ? prod.TechCourier : "-N/A-"}
                {secureLocalStorage.getItem("loggeduser") == null ? "" : <br />}
                <b>ROHS&nbsp;:&nbsp;</b>
                {prod.RohsCompliant ? prod.RohsCompliant : "-N/A-"}
                <br />
                <b>FE&nbsp;:&nbsp;</b>
                {prod.FE_MATERIAL ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Functional Equivalent</Tooltip>}>
                    <ExitToAppIcon onClick={() => this.showModalFE(prod.PartNumber)} style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                ) : "-N/A-"}
                <br />
                {Cookies.get("HP_Country") === "US" ? (
                  <div>
                    {/* <b>Click to Buy&nbsp;:&nbsp;</b> */}
                    {/* 
                        //   href={`${prod.BuyLink}`}
                        //   target="_blank"
                        //   rel="noopener noreferrer"*/}
                    {prod.BuyLink != null ? (
                      <a href="#" rel="noopener noreferrer" onClick={() => this.buylink(prod.BuyLink, prod.PartNumber)}>
                        <img src={buy} alt="BuyLink" />
                      </a>
                    ) : ""}
                  </div>
                ) : null}
                {prod.OrderFlag === "1" ? (
                  <input type="button"
                    value={!presentInCart ? "Add to Cart" : "Remove from Cart"}
                    className="cart-button"
                    onClick={() =>
                      this.handleCartChange(!presentInCart, prod)
                    }
                  >

                  </input>
                ) : null}

              </React.Fragment>
            );
          },
        },

      ]
      : [
        {
          dataField: "PartPhotoFlag",
          text: "Photo",
          formatter: (col, row, index) =>
            col ? (
              <FontAwesomeIcon
                icon="camera"
                onClick={() => this.showModal(row.PartNumber)} style={{ cursor: 'pointer' }}
              />
            ) : null,
          headerStyle: () => {
            return { width: "2%" };
          },
        },
        {
          dataField: "OrderFlag",
          text: "Add to Cart",
          formatExtraData: (partNumber) => {
            // console.log("formatter data", a, b, c);
            return cartQty[partNumber] ? true : false;
          },
          formatter: (col, row, index, extra) => {
            const letEnabled = extra(row.PartNumber);
            return col === "1" ? (
              <input
                type="checkbox"
                checked={letEnabled}
                onChange={(e) => this.handleCartChange(e.target.checked, row)} style={{ cursor: 'pointer' }}
              ></input>
            ) : (
              "-N/A-"
            );
          },
          headerStyle: () => {
            return { width: "4%" };
          },
        },
        {
          dataField: "PartNumber",
          text: "Part Number",
          headerStyle: () => {
            return { width: "6%" };
          },
        },
        {
          dataField: "EnhancedDescription",
          text: "Part Description ",
          formatter: (col) => (
            <div dangerouslySetInnerHTML={{ __html: col }}></div>
          ),
          headerStyle: () => {
            return { width: "35%" };
          },
        },
        {
          dataField: "CsrFlag",
          text: "CSR ",
          headerStyle: () => {
            return { width: "1%" };
          },
        },
        secureLocalStorage.getItem("loggeduser") == null ? {
          dataField: "TechCourier",
          text: "Tech Courier",
          hidden: true,
          headerStyle: () => {
            return { width: "3%" };
          },
        } : {
          dataField: "TechCourier",
          text: "Tech Courier",
          headerStyle: () => {
            return { width: "3%" };
          },
        },
        {
          dataField: "RohsCompliant",
          text: "ROHS",
          headerStyle: () => {
            return { width: "2%" };
          },
        },
        {
          dataField: "FE_MATERIAL",
          text: "FE ",
          headerStyle: () => {
            return { width: "2%" };
          },
          //formatter: (cell, prod, row, index) => {
          formatter: (col, prod, row, index) => {
            return col ?
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Functional Equivalent</Tooltip>}
              >
                <ExitToAppIcon onClick={() => this.showModalFE(prod.PartNumber)} style={{ cursor: 'pointer' }} />
              </OverlayTrigger>
              : "-N/A-"
          }
        },
      ];
    // {`${row.BuyLink}`}
    // onClick={this.buylink(prod.BuyLink)}
    if (!mobileView && Cookies.get("HP_Country") === "US") {
      columns.splice(2, 0, {
        dataField: "BuyLink",
        text: "Click to Buy",
        formatter: (col, row, index) =>
          col ? (
            <a
              href="#"
              rel="noopener noreferrer"
              onClick={() => this.buylink(row.BuyLink, row.PartNumber)}
            >
              <img src={buy} alt="BuyLink" />
            </a>
          ) :
            "-N/A-",
        headerStyle: () => {
          return { width: "4%" };
        },
      });
    }

    return (
      <div >
        <React.Fragment>
          {/* <div > 
              <OverlayTrigger
                      placement = "left"
                      overlay = {<Tooltip>Go To Top</Tooltip>}> 
              <FaArrowCircleUp className='go-to-top-button' onClick={this.scrollToTop.bind(this)} 
              style={{display: visible ? 'inline' : 'none'}}     
              />
              </OverlayTrigger>
             </div> */}
          {/* <ScrollUpButton></ScrollUpButton> */}

          <BootstrapTable
            keyField="PartNumber"
            data={data}
            //data={modifiedData}
            columns={columns}
            striped
          // pagination={data.length > 10 ? paginationFactory() : undefined}
          //pagination={modifiedData.length > 10 ? paginationFactory() : undefined}
          />

          <React.Fragment>
            <Modal
              size="lg"
              /* dialogClassName={"my-modal"}
              top */
              scrollable
              show={showModal}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Photo</Modal.Title>
              </Modal.Header>
              <Modal.Body id="PopupId">
                {/* <img className="img-fluid" src={imagePath} alt="img" /> */}
                {/* {isLoad && (
                <div>Loading...... </div>
                 )}  */}

                <PartImages src={imagePath} partnumber={this.state.part_number} handleLoader={this.handleLoader.bind(this)} />
              </Modal.Body>
              <Modal.Footer style={{ alignItems: "baseline", justifyContent: "flex-end" }}>
                <div id="imgDiv">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" for="MyImages">Select To View Additional Images</span>
                    </div>
                    <select class="custom-select" id="MyImages" onChange={this.getImage} >

                    </select>
                  </div>
                </div>
                <Button id="Print" onClick={this.PrintPhoto}>
                  Print
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>

          <React.Fragment>
            <Loading loading={FELoading} >
              <Modal
                size="lg"
                // top
                scrollable
                show={showModalFE}
                onHide={this.closeModalFE}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Functional Equivalent</Modal.Title>
                </Modal.Header>
                <Modal.Body id="PopupId">
                  <FunctionalEquivalent data={partFEData} />
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
              </Modal>
            </Loading>
          </React.Fragment>
          {/* </Loading> */}

        </React.Fragment>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  let cartQty = {};
  state.cart.cartItems.forEach((item) => (cartQty[item.PartNumber] = item.qty));
  return {
    ...state,
    cart: state.cart.cartItems,
    cartQty,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCarItems: (newCart) => {
      dispatch(setCarItems(newCart));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewHOC(DescProductList));
