// npm install react-cookie
import React from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';// Added By Shreyas for local storage security.
import './design.css';
import NotificationSystem from 'react-notification-system';
import { Dropdown } from 'react-bootstrap';
import Loading from './Loading';
import Cookies from 'js-cookie'
import { setCookie, getQueryParam, getURLQueryString, getAuthHeader, getAuthHeaderUID, bodyParametersUID } from './Util';
import { GETGLBURL, GET_COUNTRIES, GENERIC_SEARCH, PARTLIST, PRODUCTLIST, SERIALNUMBER, FUNCTIONAL, GETUIDRESPONSE, GETUIDTOKEN } from './constants/APIConstants';
import { withRouter } from 'react-router-dom';
import { SEARCH_TEXT, SEARCH_BY, SEARCH_MODES, Code_Url } from './constants/constants';
import "./nav.css";
import { Link } from "react-router-dom";
import { getLocationObject, generateToken } from "./Util";
import Login from "./login";
import { isMobile } from 'react-device-detect';
import GoToTop from './GoToTop';
import Select from "react-select";
import qs from 'query-string';
import {tokenurlforOAuth, clientvaluesforOAuth} from './constants/APIConstants';
export function toBase64(input) {  return Buffer.from(input, 'utf-8').toString('base64')}
export const TOKEN_EXP = `${process.env.REACT_API_TOKEN_EXP}`

//import { useCookies, Cookies } from "react-cookie";

class Form extends React.Component
{
  state = {
    FieldName: '',
    country: '',
    loading: false,
    searchBy: '',
    searchyByText: '',
    arraySearchBy: '',
    topmenuValue: '',
    code : '',
    User : '',
  };

  componentDidMount() {
    getURLQueryString();
    generateToken();
    //setCookie('HP_Country', "US");Commented for adding Home page Changes
    //setCookie("Search_Text","Generic Search");
    if(localStorage.getItem("Temp_Country")!='' && localStorage.getItem("Temp_Country")!=null)// Added By Shreyas For Home Page Country Drop down--Start Here
    {
      setCookie('HP_Country',localStorage.getItem("Temp_Country"));
      //localStorage.setItem("Temp_Country",'');
    }
    else
    {
    setCookie('HP_Country', "US");
    localStorage.setItem("Temp_Country",'US');
    }// Added By Shreyas For Home Page Country Drop down--End Here


    // let values = this.props.location.search;

    // let username = localStorage.getItem("loggeduser");

    // if(username == null)
    // {
    //   if(values.includes("code"))
    //   {
    //     var array = values.split("=");
    //     //localStorage.setItem("loggeduser",array[1]);
    //     //this.setState({ User: localStorage.getItem("loggeduser") });
    //     console.log(localStorage.getItem("loggeduser"));
    //   }
    // }
    // else{
    //   localStorage.setItem("loggeduser",username);
    //   this.setState({ User: username });
    // }
    let username = secureLocalStorage.getItem("loggeduser");    
    if(username == "UID_Failed")
    {
         alert("We are facing problem on HP UID authentication. Please try again");
         secureLocalStorage.removeItem("loggeduser")
    }

    this.setState({
      countryLoading: true,
      country: Cookies.get('HP_Country') || 'US',
      FieldName: getQueryParam(this.props, SEARCH_TEXT),
      searchBy: getQueryParam(this.props, SEARCH_BY),//Added
      //topmenuValue :Cookies.get("Search_Text")
    },
      () => {
        this.state.FieldName && this.loadData()
      });
    this.notificationSystem = React.createRef();
    this.setusertype();
    //alert(getAuthHeader());
    axios.get(GET_COUNTRIES(localStorage.getItem("usertype")), {
      headers: {
        ...getAuthHeader()
      }
      })
      .then((res) => {
        const data = res.data.Body;
        let dat = data.map((con, index) => {
          return { key: con.CountryCode, value: con.CountryName }
        })
        this.setState({ data: dat, countryLoading: false })
      }, (error) => {
        // console.log(error);
        this.setState({ countryLoading: false })
      })
      
  document.getElementById("")
}

  getUID = (input) => {
    this.setState({ countryLoading: true })
    let urlprod;
    urlprod = GETUIDRESPONSE(input);
    axios.get(urlprod, {
      headers: {
        ...getAuthHeaderUID()
      },

    })
      .then((res) => {
        this.setState({ data: res.data.Body, countryLoading: false })
        console.log(res.data.Body);
      }, (error) => {
        this.setState({ data: null, countryLoading: false })
      })
  }

  setusertype = () => {
    let username = secureLocalStorage.getItem("loggeduser");
    if(isMobile)
    {
      if(username == null)
      {
        localStorage.setItem("usertype","MEX");
      }
      else
      {
        localStorage.setItem("usertype","MIN");
      }
    }
    else
    {
      if(username == null)
      {
        localStorage.setItem("usertype","EXT");
      }
      else
      {
        localStorage.setItem("usertype","INT");
      }
    }
  }

  removeCookie = (e) => {
    e.preventDefault();
    secureLocalStorage.removeItem("loggeduser");
    this.setState({ User: null });
    alert("LogOut Success!");
    window.location.href = "/";
  }

  addNotification = text => {
    const notification = this.notificationSystem.current;
    //  console.log('noti', notification)
    notification.addNotification({
      message: text,
      level: 'error',
      position: 'tc',
    });
  };

  loadData = () => {
    const { FieldName, country, searchBy } = this.state;
    if (country) {
      this.setState({ dataLoading: true }, () => this.props.onSubmit(null))
      let url;
      switch (searchBy) {
        case SEARCH_MODES.PART: url = PARTLIST(FieldName, country, localStorage.getItem("usertype"))
          break;
        case SEARCH_MODES.PRODUCT: url = PRODUCTLIST(FieldName, country,localStorage.getItem("usertype"))
          break;
        case SEARCH_MODES.SEARIAL_NUMBER: url = SERIALNUMBER(FieldName, country,localStorage.getItem("usertype"))
          break;
        case SEARCH_MODES.SERIAL_WP: url = SERIALNUMBER(FieldName, country, localStorage.getItem("usertype"))
          break;
        case SEARCH_MODES.FUNCTIONAL_EQUIVALENT: url = FUNCTIONAL(FieldName, country,localStorage.getItem("usertype"))
          break;
        default:
          url = GENERIC_SEARCH(FieldName, country, localStorage.getItem("usertype"))
      }
      // console.log('url', url, searchBy)
      axios.get(url, {
        headers: {
          ...getAuthHeader()
        }
      })
        .then((response) => {
          // console.log('call succes data:', response)
          this.props.onSubmit(response.data.Body ? response.data.Body : !searchBy ? {} : null, searchBy)
          this.setState({ dataLoading: false })
        }, (error) => {
          // console.log(error);
          this.setState({ dataLoading: false })
        })
    }
    else {
      this.addNotification('Please select the country')
    }
  }

  componentWillReceiveProps(nextProps) {
 //   console.log("getitem", getStoredCart())
    // component
    // console.log('update')
    // const prevText = getQueryParam(this.props, SEARCH_TEXT);
    // const text = getQueryParam(nextProps, SEARCH_TEXT)
    const prevMode = this.state.searchBy;
    const nextMode = getQueryParam(nextProps, SEARCH_BY)
    const prevText = this.state.FieldName;
    //Modified by Shreyas - Keep the text value even after changing Search Type Dropdown - Start
   //const text = getQueryParam(nextProps, SEARCH_TEXT)
    const text = this.state.FieldName;
   //Modified by Shreyas - Keep the text value even after changing Search Type Dropdown - End.
   // console.log('prevtext', prevText, 'text=', text, 'prevMode=', prevMode, 'nextMode=', nextMode)
    if ((prevText !== text) || prevMode !== nextMode) {
      this.setState({ searchBy: nextMode, FieldName: text }, () => {
        this.props.onSubmit(null)
      });
    }
  }

  componentDidUpdate(){
    const withclasses=Array.from(
      document.getElementsByClassName("dropdown-item")
    );
    withclasses.forEach(element=>{
      if(element.innerText==this.state.topmenuValue){
      element.classList.add("active");
      }
      else{
        element.classList.remove("active");
      }
    });
  }

  handleSubmit = async (event) => {
    const { searchBy } = this.state;
    event.preventDefault();
    this.loadData()
    this.props.history.push({
      pathname: `${process.env.REACT_APP_HOME_DIRECTORY}`,
      search: `?${SEARCH_TEXT}=${this.state.FieldName}${searchBy ? `&${SEARCH_BY}=${searchBy}` : ''}`

    })
  };

  getSearchMode = () => {
    const { searchBy } = this.state;
    switch (searchBy) {
      case SEARCH_MODES.PART: return 'Enter Part Number';
      case SEARCH_MODES.PRODUCT: return 'Enter Product Number';
      case SEARCH_MODES.SERIAL_WP: return 'Enter Serial Number';
      case SEARCH_MODES.FUNCTIONAL_EQUIVALENT: return 'Enter Part Number';
      case SEARCH_MODES.WILD_CHAR: return 'Wild Character Search';
      default: return 'Enter Part/Product/Serial Number';
    }
  }

  setSearchModeLink = () => {


    const { searchBy, arraySearchBy, topmenuValue } = this.state;
    if(searchBy != arraySearchBy)
    {
      this.setState({ searchyByText: this.getSearchResult(searchBy), arraySearchBy: searchBy });
      this.setState({topmenuValue : this.getSearchResultText(searchBy)});
    }
    // var list=document.getElementsByClassName("dropdown-item");
    // for (var i = 0; i < list.length; ++i) {
    //   if(topmenuValue==list[i].innerText){
    // list[i].classList.add('active');
    //   }
    //   else{
    //     list[i].classList.remove('active');
    //   }
    // }
     //Added by Shreyas to highlight the selected text--End


  }

  getSearchResult = (searchBy) => {
    switch (searchBy) {
      case SEARCH_MODES.PART: return 'Search By - Part Number';
      case SEARCH_MODES.PRODUCT: return 'Search By - Product Number';
      case SEARCH_MODES.SERIAL_WP: return 'Search By - Serial Number';
      case SEARCH_MODES.FUNCTIONAL_EQUIVALENT: return 'Search By - Part Number';
      case SEARCH_MODES.WILD_CHAR: return 'Search By - Wild Char';
      default: return 'Search By - Part/Product/Serial Number';
    }
  }

  getSearchResultText = (searchBy) => {
    switch (searchBy) {
      case SEARCH_MODES.PART: return 'Part';
      case SEARCH_MODES.PRODUCT: return 'Product';
      case SEARCH_MODES.SERIAL_WP: return 'Serial Number';
      case SEARCH_MODES.FUNCTIONAL_EQUIVALENT: return 'Functional Equivalent';
      case SEARCH_MODES.WILD_CHAR: return 'Wild Character';
      default: return 'Generic Search';
    }
  }

  render() {

    const { countryLoading, dataLoading, country, data, topmenuValue, User } = this.state;

    return (

      <React.Fragment>
        <Loading loading={countryLoading || dataLoading} >
          <div>
            <br />
            <Login />
            <div className="row">
              <div className="col-sm-6">
              </div>
              <div className="col-sm-6">
                <div style={{ float: 'right' }} className="country-container">
                  {/* <Dropdown onSelect={(a, b) => {
                    this.setState({ country: a })
                    setCookie('HP_Country', a)
                  }}>
                    <label><b>Select Country: &nbsp;</b></label>
                    <Dropdown.Toggle id="dropdown-basic" className={"country-selector"} >
                      {((data || []).find(c => c.key === country) || {}).value || 'Please select your country'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu onSelect={(a, b) => {
                      //  console.log("dropdownselect", a, b.currentTarget)
                    }}>
                      {
                        (data || []).map((country, index) =>
                          <Dropdown.Item eventKey={country.key} key={index}>{country.value}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <label><b>Select Country: &nbsp;</b></label>

                    <Select id="dropdown-basic" className={"country-selector"}
                    options={(data || []).map((country, index) => ({
                      value: country.key,
                      label: country.value,
                    }))}
                    defaultValue={{ label: ((data || []).find(c => c.key === country) || {}).value || 'Please select your country'  }}
                    onChange={(country, index) => {
                          this.setState({ country: country.value})
                          setCookie('HP_Country', country.value)
                          localStorage.setItem("Temp_Country",country.value)
                          }}
                    />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h1>
                  <span id="ctl00_PageTitle">HP PartSurfer</span>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <span id="ctl00_SubTitle">HP PartSurfer provides fast, easy access to service parts information for a wide range of HP Products.</span>
              </div>
              <br/>
            </div>

            <form onSubmit={this.handleSubmit}>
            <div >
              <div className="input-group">
              <div className="input-group-prepend">
            {isMobile?(<button type="button" class="search btn btn-primary dropdown-toggle" data-toggle="dropdown" data-width="">
                                    <span class="caret"></span>

                                </button>):
              <button type="button" style={{width:"177px"}} class="search btn btn-primary dropdown-toggle" data-toggle="dropdown" data-width="">
                                    <span class="caret"></span>
                                  <span >{topmenuValue}</span>
                                </button>}
                                <ul class="dropdown-menu pull-right" role="menu" id="searchList">
                                <li class="list" >
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.PART)}
                            onClick={this.setSearchModeLink()}
                          >
                            Part
                          </Link>
                          </li>
                          <li class="list">
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.PRODUCT)}
                            onClick={this.setSearchModeLink()}
                          >
                            Product
                          </Link>
                          </li>
                          <li class="list">
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.SERIAL_WP)}
                            onClick={this.setSearchModeLink()}
                          >
                            Serial Number
                          </Link>
                          </li>
                          <li class="list">
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.FUNCTIONAL_EQUIVALENT)}
                            onClick={this.setSearchModeLink()}
                          >
                            Functional Equivalent
                          </Link>
                          </li>
                          <li class="list">
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.WILD_CHAR)}
                            onClick={this.setSearchModeLink()}
                          >
                            Wild Character
                          </Link>
                          </li>
                          <li class="list">
                          <Link className="dropdown-item" to={getLocationObject()} onClick={this.setSearchModeLink()}>
                            Generic Search
                          </Link>
                      </li>

                                </ul>
                                </div>
                {/* <input type="text" className="form-control  col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" placeholder={this.getSearchMode()} value={this.state.FieldName || ''}
                  onChange={event => this.setState({ FieldName: event.target.value })} required /> */}

              <input type="text" className="form-control  col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" placeholder={this.getSearchMode()} value={this.state.FieldName }  onChange={event => this.setState({ FieldName: event.target.value })} required />
                <div className="input-group-append">
                  <button type="submit" className="search btn btn-primary" >
                    <i className="fa fa-search"></i>
                  </button>


                  {/* <button type="button" class="search btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                    <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu pull-right" role="menu">
                                <li >
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.PART)}
                            onClick={this.setSearchModeLink()}
                          >
                            Part
                          </Link>
                          </li>
                          <li>
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.PRODUCT)}
                            onClick={this.setSearchModeLink()}
                          >
                            Product
                          </Link>
                          </li>
                          <li>
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.SERIAL_WP)}
                            onClick={this.setSearchModeLink()}
                          >
                            Serial Number
                          </Link>
                          </li>
                          <li>
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.FUNCTIONAL_EQUIVALENT)}
                            onClick={this.setSearchModeLink()}
                          >
                            Functional Equivalent
                          </Link>
                          </li>
                          <li>
                          <Link
                            className="dropdown-item"
                            to={getLocationObject(null, SEARCH_MODES.WILD_CHAR)}
                            onClick={this.setSearchModeLink()}
                          >
                            Wild Character
                          </Link>
                          </li>
                          <li>
                          <Link className="dropdown-item" to={getLocationObject()} onClick={this.setSearchModeLink()}>
                            Generic Search
                          </Link>
                      </li>

                                </ul> */}

                {/* &nbsp;&nbsp;&nbsp; */}
                {/* id="navbarSupportedContent"                */}
                  {/* Santhosh - Searchby Menu */}

              </div>
            </div>
            </div>


                {/* <br/> */}

                {/* <span style={{color: "#007bff"}}>Selected Search Type : {topmenuValue} </span> */}
            {/* <div className="row">

              <div className="col-md-9">
              {this.state.searchyByText && <div style={{color: "#007bff"}}>{this.state.searchyByText}</div>
              }
              </div>
            </div> */}
              <div className="row">
                <p></p>
              </div>
              <NotificationSystem ref={this.notificationSystem} />
            </form>
          </div>
        </Loading>


        </React.Fragment>
    );
  }
}

export default withRouter(Form)
