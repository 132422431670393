import Cookies from "js-cookie";
import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { setCarItems } from "../Actions";
import Cart from "../Cart.png";
import { getItemIndex } from "../CartUtils";
import "./cartModal.css";
import { ViewHOC } from "./ViewHOC";
import BootstrapTable from "react-bootstrap-table-next";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { size } from "lodash";
import { CARTMETRIC } from './constants/APIConstants';
import axios from 'axios';
import { getAuthHeader } from './Util';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class CartModal extends React.Component {
  constructor() {
    super();
    this.state = {
      showHide: false,
      cart: [],
    };
  }

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  removeItem = (partNumber) => {
    const { cart, setCartItems } = this.props;
    const ind = getItemIndex(partNumber, cart);
    if (ind > -1) {
      let newCart = cart || [];
      newCart.splice(ind, 1);
      console.log("remove", newCart);
      setCartItems(newCart);
    }
    console.log("partnum", partNumber);
  };

  updateQuantity = (partNumber, qty) => {
    console.log(
      "quant",
      partNumber,
      typeof qty,
      typeof Number(qty),
      Number("0001"),
      Number(qty)
    );
    qty = Number(qty);
    const { cart, setCartItems } = this.props;
    const ind = getItemIndex(partNumber, cart);
    if (ind > -1) {
      if (qty > 0 && qty < 100) {
        cart[ind].qty = qty;
      } else {
        alert("Quantity between 1 to 99 Only");
        cart[ind].qty = 1;
      }
      setCartItems(cart);
    }
    let urlprod;
      urlprod = CARTMETRIC(partNumber, qty, 2);
        axios.get(urlprod, {
            headers: {
              ...getAuthHeader()
            }
          })
            .then((response) => {
            }, (error) => {
        })
  };

  truncate = (source, size) => {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  };

  getRedirectURL = () => {
    const { cartQty } = this.props;
    console.log("cq", cartQty);
    let URL=`${process.env.REACT_APP_HPPS_PATH}`
    URL = `${URL}&cc=${Cookies.get("HP_Country") || "US"}`;
    Object.entries(cartQty).forEach(
      ([partNumber, qty], index) =>
        (URL = `${URL}&partnumber${index}=${partNumber}&.qty${index}=${qty}`)
    );
    return window.open(URL, "_blank");
  };

  render() {
    const { mobileView} = this.props;
    console.log("props value",this.props.cart)
    const columns = [
      {
        dataField: "PartNumber",
        text: "Part Number",        
        headerStyle: () => {
          return { width: "2%" };
        },
      },
      {
        dataField: "EnhancedDescription",
        text: "Part Description", 
        formatter: (col) => (
          <div dangerouslySetInnerHTML={{ __html: col }}></div>
        ),       
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "qty",
        text: "Quantity",
        formatter: (col, prod, row, index) =>
          col ? (
            <div className="input-group">
              <span className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default btn-number"
                  onClick={() => {
                    prod.qty - 1 > 0 &&
                      this.updateQuantity(
                        prod.PartNumber,
                        prod.qty - 1
                      );
                  }}
                >
                  <i className="fas fa-minus" />
                </button>
              </span>
               <div onChange={(e) => {
                  this.updateQuantity(
                  prod.PartNumber,
                  e.currentTarget.value
                );
                }}>
              <Container style={{alignItems:'center',border:'inset',maxWidth:'100%',paddingBottom:'10%',minWidth:'50%',paddingLeft:'10%'}}>
              {prod.qty}</Container>
              </div>
              <span className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default btn-number"
                  onClick={() => {
                    prod.qty + 1 < 100 &&
                      this.updateQuantity(
                        prod.PartNumber,
                        prod.qty + 1
                      );
                  }}
                >
                  <i className="fas fa-plus" />
                </button>
              </span>
          </div>
        ) : null,
        headerStyle: () => {
          return { width: "3%" };
        },
      },
      {
        dataField: "",
        text: "Action",
        formatter : (col, prod, row, index) =>
          (
            <span
                onClick={() =>
                this.removeItem(prod.PartNumber)
                 }
            >
              <i
               className="fa fa-trash"
               aria-hidden="true"
              />
            </span>
          ),
        headerStyle: () => {
          return { width: "2%" };
        },
      },      
    ];
    return (
      <div>
        <React.Fragment>
          {mobileView ? (
            <React.Fragment>
              <img
                alt="cart"
                src="https://itg-psurf-web.glbinc.hp.com/PartSurfer/img/Cart.png"
                width="28"
                height="22"
                onClick={() => this.handleModalShowHide()} style={{cursor:'pointer'}}
              />

              <span className="badge badge-warning" id="lblCartCount">
                {(this.props.cart || []).length}
              </span>

              <Modal
                show={this.state.showHide}
                size="lg"
                dialogClassName={"my-modal"}
              >
                <Modal.Header closeButton onHide={this.handleModalShowHide}>
                  <b>Cart Items</b>
                </Modal.Header>
                <Modal.Body>
                  {(this.props.cart || []).length > 0 ? (
                    <>
                      {this.props.cart.map((cartItem, idx) => {
                        return (      
                            <Row key={idx}>
                                  <Row style={{width: "100%"}}>
                                  <Col>
                                  <div style={{ marginLeft: "10px", width: "100%"}}>
                                  <hr style={{ borderTop: "3px", borderTopStyle: "solid", borderTopColor:"#bbb"}}/> 
                                    <div style={{ fontSize: "14px"}}><b>Part Number:</b>   
                                    {/* <div style={{ fontSize: "12px"}}> */}
                                    {cartItem.PartNumber}
                                    {/* </div> */}
                                    </div> 
                                  </div> 
                                 
                                  </Col>
                                  </Row>
                                <Row style={{width: "100%"}}>
                                  <Col>                
                                   <div style={{ marginLeft: "10px", width: "100%"}}>
                                     {/* <hr style={{ borderTop: "3px", borderTopStyle: "solid", borderTopColor:"#bbb"}}/>  */}
                                    <div style={{ fontSize: "14px"}}><b>Part Description:</b> <br/></div>   
                                    <div style={{ fontSize: "12px"}}>
                                      <div dangerouslySetInnerHTML={{ __html: cartItem.EnhancedDescription }}></div>
                                    </div>
                                  </div> 
                                  <br/>
                                  </Col>
                                </Row>                                                              
                                {/* </Col> */}
                                <Col xs={6}>
                                  <div className="input-group">
                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        className="btn btn-default btn-number"
                                        onClick={() => {
                                          cartItem.qty - 1 > 0 &&
                                            this.updateQuantity(
                                              cartItem.PartNumber,
                                              cartItem.qty - 1
                                            );
                                        }}
                                      >
                                        <i className="fas fa-minus" />
                                      </button>
                                    </span>
                                    <div onChange={(e) => {
                                        this.updateQuantity(
                                          cartItem.PartNumber,
                                          e.currentTarget.value
                                        );
                                      }}>
                                        <Container style={{alignItems:'center',border:'inset',maxWidth:'100%',paddingBottom:'10%',minWidth:'50%',paddingLeft:'10%'}}>
                                        {cartItem.qty}</Container>
                                    </div>
                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        className="btn btn-default btn-number"
                                        onClick={() => {
                                          cartItem.qty + 1 < 100 &&
                                            this.updateQuantity(
                                              cartItem.PartNumber,
                                              cartItem.qty + 1
                                            );
                                        }}
                                      >
                                        <i className="fas fa-plus" />
                                      </button>
                                    </span>
                                  </div>
                                </Col>
                              <Col >
                                <span
                                  onClick={() =>
                                    this.removeItem(cartItem.PartNumber)
                                  }
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </span>
                                </Col>
                            </Row>
                        
                          
                        );
                      })}
                      <Modal.Footer>
                        <input
                          type="button"
                          className="btnStylePrimary"
                          value="Check Out"
                          onClick={() => this.getRedirectURL()}
                        />
                      </Modal.Footer>
                    </>
                  ) : (
                    <> You have no items in your shopping cart.</>
                  )}
                </Modal.Body>
              </Modal>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img
                alt="cart"
                src="https://itg-psurf-web.glbinc.hp.com/PartSurfer/img/Cart.png"
                width="28"
                height="22"
                onClick={() => 
                  this.handleModalShowHide()
                } style={{cursor:'pointer'}}
              />

              <span className="badge badge-warning" id="lblCartCount">
                {(this.props.cart || []).length}
              </span>

              <Modal
                show={this.state.showHide}
                size="lg"
                dialogClassName={"my-modal"}
              >
                <Modal.Header closeButton onHide={this.handleModalShowHide} >
                <b>Cart Items</b>
                </Modal.Header>
                  <Modal.Body >
                    {(this.props.cart || []).length > 0 ? (
                      <>
                        <Container>
                          <Modal.Body>
                          <BootstrapTable
                              keyField="PartNumber"
                              //data={data}
                              data={this.props.cart}
                              columns={columns}
                              striped                              
                            />   
                          </Modal.Body>
                        </Container>
                        <Modal.Footer>
                          <input
                            type="button"
                            className="btnStylePrimary"
                            value="Check Out"
                            onClick={() => this.getRedirectURL()}
                          />
                        </Modal.Footer>
                      </>
                    ) : (
                      <> You have no items in your shopping cart.</>
                    )}
                  </Modal.Body>
                
              </Modal>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let cartQty = {};
  state.cart.cartItems.forEach((item) => (cartQty[item.PartNumber] = item.qty));
  return {
    ...state,
    cart: state.cart.cartItems,
    cartQty,
  };
};

const mapDispatchProps = (dispatch) => ({
  setCartItems: (cart) => dispatch(setCarItems(cart)),
});

export default connect(mapStateToProps, mapDispatchProps)(ViewHOC(CartModal));
