import React, { Component } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { ViewHOC } from "./ViewHOC";
import secureLocalStorage from "react-secure-storage";

class SpareBOM extends Component {
  constructor() {
    super();
    this.state = {
      Ishide: false,
    };
  }



  isValid = () => {
    const { data } = this.props;
    return (
      data.length !== 1 &&
      data.filter((row) => row.PartNumber !== "-N/A-").length > 0
    );
  };

  render() {
    const { data, mobileView } = this.props;
    const {Ishide} = this.state;
    const columns = mobileView
      ? [
          {
            text: "#",
            headerAttrs: {
              hidden: true,
            },
            formatter: (col, prod, index) => {
              return (
                <React.Fragment key={prod.spare_part_no}>
                  {this.isValid() ? (
                    <React.Fragment>
                      <b>Parent Part Number&nbsp;:&nbsp;</b>
                      {prod.part_number}
                      <br />
                      <b>Part Number&nbsp;:&nbsp;</b>
                      {prod.spare_part_no}
                      <br />
                      <b>Spare Part Description &nbsp;:&nbsp;</b>
                      <div dangerouslySetInnerHTML={{ __html: prod.spare_part_description }}></div>
                      {/* {prod.spare_part_description} */}
                      <br />
                      <b>Enhanced Part Description &nbsp;:&nbsp;</b>
                      {prod.spare_enhance_desc}
                      <br />
                      <b>Category&nbsp;:&nbsp;</b>
                      {prod.sp_category}
                      <br />
                      {secureLocalStorage.getItem("loggeduser") == null ?  "": <b>Tech Courier&nbsp;:&nbsp;</b>}
                      {secureLocalStorage.getItem("loggeduser") == null ?  "": prod.sp_techcourier}
                      {secureLocalStorage.getItem("loggeduser") == null ?  "":<br />}
                      <b>CSR&nbsp;:&nbsp;</b>
                      {prod.sp_csrflag}
                      <br />
                      <b>ROHS&nbsp;:&nbsp;</b>
                      {prod.sp_rohs_status_code}
                      <br />
                    </React.Fragment>
                  ) : (
                    <p> No additional information found in PartSurfer.</p>
                  )}
                </React.Fragment>
              );
            },
          },
        ]
      : [
          {
            dataField: "part_number",
            text: "Parent Part Number",
            headerStyle: () => {
              return { width: "10%" };
            },
          },
          {
            dataField: "spare_part_no",
            text: "Part Number",
            headerStyle: () => {
              return { width: "15%" };
            },
          },
          {
            dataField: "spare_part_description",
            text: "Basic Spare Part Description",
            formatter: (col) => (
              <div dangerouslySetInnerHTML={{ __html: col }}></div>
            ),
            headerStyle: () => {
              return { width: "20%" };
            },
          },
          {
            dataField: "spare_enhance_desc",
            text: "Enhanced Spare Part Description",
            headerStyle: () => {
              return { width: "20%" };
            },
          },
          {
            dataField: "sp_category",
            text: "Category",
            headerStyle: () => {
              return { width: "10%" };
            },
          },
          {
            dataField: "sp_csrflag",
            text: "CSR",
            headerStyle: () => {
              return { width: "5%" };
            },
          },
          {
            dataField: "sp_rohs_status_code",
            text: "ROHS",
            headerStyle: () => {
              return { width: "10%" };
            },
          },
          secureLocalStorage.getItem("loggeduser") == null ? {
            dataField: "sp_techcourier",
            text: "Tech Courier",
            hidden: true,
            headerStyle: () => {
              return { width: "5%" };
            },
          } : {
            dataField: "sp_techcourier",
            text: "Tech Courier",
            headerStyle: () => {
              return { width: "5%" };
            },
          },
        ];

    return (
      <React.Fragment>
        {this.isValid() ? (
          <BootstrapTable
            keyField="spare_part_no"
            striped
            data={data}
            columns={columns}
            
          />
        ) : (
          <div> No additional information found in PartSurfer.</div>
        )}
        {/* pagination={data.length > 10 ? paginationFactory() : undefined} */}
      </React.Fragment>
    );
  }
}

export default ViewHOC(SpareBOM);
