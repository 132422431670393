import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const getDesc = (mode, payload) => {
    switch (mode) {
        //commented by shridhar for wild card search-blue section need to remove
        // case 'GenericSearch':
        //     return (<div>
        //         <div style={{ color: "white" }}> Product Number&nbsp;:&nbsp;&nbsp;{payload[0].ProductNumber === '-N/A-' ? 'Not Found' : payload[0].ProductNumber} <br /></div>
        //         <div style={{ color: "white" }}> Product Name&nbsp;:&nbsp;&nbsp;{payload[0].ProductNumber === '-N/A-' ? 'Not Found' : payload[0].ProductName} <br /></div>
        //     </div>)
        case 'SERIAL':
            return (<div>
                {payload &&
                <div>                    
                    <div style={{ color: "white" }}>Serial Number&nbsp;:&nbsp;&nbsp;{payload.wwsnrsinput.serial_no === '-N/A-' ? 'Not Found' : payload.wwsnrsinput.serial_no}<br /></div>
                    <div style={{ color: "white" }}>Product Number&nbsp;:&nbsp;&nbsp;{payload.wwsnrsinput.product_no === '-N/A-' ? 'Not Found' : payload.wwsnrsinput.product_no}<br /></div>
                    <div style={{ color: "white" }}>Description&nbsp;:&nbsp;&nbsp;{payload.wwsnrsinput.user_name === '-N/A-' ? 'Not Found' : payload.wwsnrsinput.user_name}<br /></div>
                    <div style={{ color: "white" }}>ROHS Status&nbsp;:&nbsp;&nbsp;{payload.roHS_unit_status.rohs_status_code === '-N/A-' ? 'Not Found' : payload.roHS_unit_status.rohs_status_code}<br /></div>
                </div>
                }
            </div>)
        case 'PRODUCT':
            return (<div>  
                 {(payload || []).length > 0 && (
                     <div>
                        <div style={{ color: "white" }}>Product Number&nbsp;:&nbsp;&nbsp;{payload[0].ProductNumber? payload[0].ProductNumber: "Not Found"} <br /></div>
                        <div style={{ color: "white" }}>Description&nbsp;: &nbsp;&nbsp;{payload[0].ProductName? payload[0].ProductName: "Not Found"} <br /></div>
                    </div>
                 )}             
                
            </div>)
        default:
    }
}

export default function Details({ mode, payload, expandIconRequired, triggered, triggerAll }) {
    return (
        <div style={{ backgroundColor: "#0096D6", padding: "20px 10px" }}>
            {expandIconRequired && triggerAll ?
                <Row noGutters={true}>
                    <Col xs={'11'} sm={'11'} md={'11'} lg={'11'} xl={'11'}>
                        {getDesc(mode, payload)}
                    </Col>
                    <Col xs={'1'} sm={'1'} md={'1'} lg={'1'} xl={'1'}>
                        
                        <div style={{ float: 'right', color: 'white' }} onClick={() => { triggerAll(!triggered) }}>
                            {triggered ? <FontAwesomeIcon icon="minus-square" /> : <FontAwesomeIcon icon="plus-square" />}
                        </div>
                    </Col>
                </Row> : getDesc(mode, payload)}
        </div>
    )
}
