import React from 'react'

export default function Header() {
    return (
        <div>
            { 
        //  console.log("header", new String( process.env.REACT_APP_HEADER_ENABLED)==="0"),
         process.env.REACT_APP_HEADER_ENABLED==="true" && <div style={{ width: '100%' }}>
                <div id="header" className="header"></div>
            </div>}
        </div>
    )
}
