import React from "react";
import { Accordion, Card, Container } from "react-bootstrap";
import "./bootstrap.min.css";
import "./design.css";
import './main.css';
import { ViewHOC } from "./ViewHOC";
import { Link } from "react-router-dom";
import { getLocationObject } from "./Util";
import Navbar from "./navbar";
import Login from "./login";

function ContactUS() {
  return (
    <React.Fragment>
      <div>  
         <Navbar />    
        <div style={{ marginRight: "10px"}}>
          <br/>
          <Login />
          <br/>
        </div>
      </div>
      <Container>
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              I have a question/comment about ...
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <b>More parts assistance ..</b>
                <br />
                For further assistance with ordering or identifying HP parts,
                please visit the &nbsp;
                <a
                  target="_blank"
                  className="underline"
                  href="https://parts.hp.com/hpparts/CountryChoice.aspx"
                  rel="noopener noreferrer"
                  style={{ color: "black" }}
                >
                  HP Parts Store
                </a>
                <br />
                <br />
                <a href="https://support.hp.com/" target="_blank">
                  <b style={{ color: "#003366" }}>
                    <span>&#62;&#62;</span> Support for my product
                  </b>
                </a>
                <br />
                <br />
                <a href="https://www.hp.com/us-en/hp-information.html" target="_blank">
                  <b style={{ color: "#003366" }}>
                    <span>&#62;&#62;</span>General HP information
                  </b>
                </a>
                <br />
                <br />
                <b>I would like to send email to HP regarding ..</b><br/>
                <br />
                <a
                  href="https://www.hp.com/us-en/shop/cv/customerservice#TechSupport"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#003366" }}
                >
                  <span>&#62;&#62;</span>
                  <b>Technical support for my HP product</b>
                </a><br/>
                <br />
                {/* <a
                  href="NewRequest"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#003366" }}
                >
                  <span>&#62;&#62;</span>
                  <b>Missing or incorrect data in PartSurfer</b>
                </a> */}
                <Link style={{ color: "#003366" }} rel="noopener noreferrer" to="/PartSurfer/NewRequest">
                  <span>&#62;&#62;</span>
                  <b>Missing or incorrect data in PartSurfer</b>
                </Link>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />        
        <div>
        <div>
            <Link className="apply btn btn-primary" to={getLocationObject()}>
              Back to home
            </Link>
            <br />
        </div>
        <br />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default ViewHOC(ContactUS);
