import React from "react";
import "./nav.css";
import { ViewHOC } from "./ViewHOC";
import { Link } from "react-router-dom";
import { getLocationObject } from "./Util";
import { MailIDS } from "./constants/constants";
import CartModal from "./CartModal";
import Loading from './Loading';
import Cookies from 'js-cookie'
// import { USER_GUIDE } from "./constants/RouteConstants";

// Added By Shreyas to Redirect Page to Home URL--Start Changed on 24 Feb 2023
function pageRefresh(event){
  event.preventDefault();
  localStorage.setItem("Temp_Country",Cookies.get('HP_Country'));// Added on:-09 March 2023
 //window.location.replace(`${process.env.REACT_APP_HOME_DIRECTORY}`);
  window.location.replace("/");
  };
// Added By Shreyas to Redirect Page to Home URL--End Changed on 24 Feb 2022
  const bannerMessage = process.env.REACT_APP_BANNER_MESSAGE || "";
function Navbar(props) {
  // console.log('mobile', x.mobileView)
//  console.log("navprop", props);
  return (
     <div>
      
      <nav
      className="navbar navbar-expand-sm navbar-light"
      style={
        props.mobileView
          ? {}
          : {
              paddingLeft: "4rem",
            }
            
      }
    >
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>      
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            {/* <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a> */}
            {/* Commented by Shreyas on 24 Feb 2023: To Add onclick event to the Home Link--- Start Here  */}
            {/* <Link className="nav-link" to={getLocationObject()}> */}
             {/* Commented by Shreyas on 24 Feb 2023: To Add onclick event to the Home Link--- End Here  */}
             {/* Added by Shreyas on 24 Feb 2023: To Add onclick event to the Home Link--- Start Here  */}           
			<Link className="nav-link" onClick={pageRefresh}>
              {/* Added by Shreyas on 24 Feb 2023: To Add onclick event to the Home Link--- End Here  */}
              Home
            </Link>
          </li>         

          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Search By
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <Link
                className="dropdown-item"
                to={getLocationObject(null, SEARCH_MODES.PART)}
              >
                Part
              </Link>
              <Link
                className="dropdown-item"
                to={getLocationObject(null, SEARCH_MODES.PRODUCT)}
              >
                Product
              </Link>
              <Link
                className="dropdown-item"
                to={getLocationObject(null, SEARCH_MODES.SERIAL_WP)}
              >
                Serial Number
              </Link>
              <Link
                className="dropdown-item"
                to={getLocationObject(null, SEARCH_MODES.FUNCTIONAL_EQUIVALENT)}
              >
                Functional Equivalent
              </Link>
              <Link
                className="dropdown-item"
                to={getLocationObject(null, SEARCH_MODES.WILD_CHAR)}
              >
                Wild Character
              </Link>
            </div>
          </li> */}
          <li className="nav-item active">
            {/* <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a> */}
            <Link className="nav-link" to='/PartSurfer/Hierarchy'>
              Hierarchy
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Self Help
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link  className="dropdown-item"  to="/PartSurfer/UserGuide">
                User Guide
              </Link>
                <Link  className="dropdown-item"  to="/PartSurfer/Faq">
                Frequently Asked Questions
                </Link>
                <Link className="dropdown-item" to="/PartSurfer/ContactUS">
                  Contact Us
                </Link>
                {/* {!MailIDS.includes(localStorage.getItem("loggeduser")) ?  "": 
                <Link className="dropdown-item" to="/PartSurfer/GenerateMetrics">
                  Generate Metrics
                </Link>  } */}
            </div>
          </li>
        </ul>
        {!props.mobileView && <CartModal />}
      </div>
      
      {props.mobileView && <CartModal />}
    </nav>
      {/* Conditionally render the banner if the bannerMessage is not empty */}
      {bannerMessage && (
        <div id="banner">  
          <marquee direction="left">{bannerMessage}</marquee>
        </div>
      )}

    </div>
  );
}

export default ViewHOC(Navbar);
