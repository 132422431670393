import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ViewHOC } from "./ViewHOC";
import Details from "./Details";
import { getURLQueryString, getURLQueryStringBySearch } from "./Util";
import { SEARCH_MODES } from "./constants/constants";

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  componentDidMount() {
    this.filterData();
  }

  filterData = (text) => {
    this.setState({
      list: this.props.data.filter((part) =>
        text
          ? part.ProductNumber.indexOf(text) > -1 ||
            part.ProductName.indexOf(text) > -1
          : true
      ),
    });
  };

  render() {
    const { data, mobileView, isDescDisabled } = this.props;

    const columns = mobileView
      ? [
          {
            text: "#",
            dataField:'',
            headerAttrs: {
              hidden: true,
            },
            formatter: (col, prod, index) => {
              return (
                <React.Fragment key={prod.ProductNumber}>
                  <b>Product/Part&nbsp;:&nbsp;</b>
                  <a
                    href={getURLQueryString(
                      prod.ProductNumber,
                      SEARCH_MODES.PRODUCT
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {prod.ProductNumber}
                  </a>
                  <br />
                  <b>Description&nbsp;:&nbsp;</b>
                  {prod.ProductName}
                  <br />
                  <br/>
                </React.Fragment>
              );
            },
          },
        ]
      : [
          {
            dataField: "ProductNumber",
            text: "Product/Part",
            formatter: (col, row, index) => (
              <a href={getURLQueryStringBySearch(col)} target="_Blank">{col}</a>
            ),
          },
          {
            dataField: "ProductName",
            text: "Description",
          },
        ];

    return (
      <React.Fragment>
        {/*
        commented by shridhar to remove details blue section for wild card search
        {!isDescDisabled && <Details mode="GenericSearch" payload={data} />}
        <br /> */}
        {this.state.list.length > 0 ? 
        <div>
        <div className="row">
          <div className="col-sm-6">
            <div className="input-group searchbox">
              <input
                type="text"
                id="search"
                name="searchtext"
                className="form-control"
                placeholder="Enter Text To Filter"
                value={this.state.searchText}
                onChange={(event) =>
                  this.setState({ searchText: event.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="col searchbox">
            <button
              name="btn-addtext"
              className="apply btn btn-primary"
              onClick={() => {
                this.filterData(this.state.searchText);
              }}
            >
              Apply
            </button>
            &nbsp;&nbsp;
            <button
              name="btn-upload"
              className="reset btn btn-primary"
              onClick={() => {
                this.filterData();
                this.setState({ searchText: "" });
              }}
            >
              Reset
            </button>
          </div>          
        </div> 
        <br/>
        <br/>
        <div>
          <BootstrapTable
            keyField="ProductNumber"
            data={this.state.list}
            columns={columns}
            striped
            pagination={data.length > 10 ? paginationFactory() : undefined}
          />
        </div>
        </div>
          : "There is no Where Used Products!" } 
      </React.Fragment>
    );
  }
}

export default ViewHOC(ProductList);
