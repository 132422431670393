import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { setCarItems } from "../Actions";
import { getItemIndex } from "../CartUtils";
import { Alert, Collapse } from "react-bootstrap";
import ProductList from "./ProductList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./design.css";
import Loading from "./Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { ViewHOC } from "./ViewHOC";
import { GET_PARTLIST, FUNCTIONAL, FUNCTIONAL_LIST, INSERTPHOTOMETRIC, BUYMETRIC } from "./constants/APIConstants";
import { getAuthHeader } from "./Util";
import buy from "../buy.gif";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import PartImages from "./PartImages";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FunctionalEquivalent from './FunctionalEquivalent';
import imageexists from "image-exists"

class PartList extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      cart: [],
      showModal: false,
      showModalFE: false,
      part: [],
      loading: false,
      collapsed: false,
      modifiedData: [],
      part_number: "",
    };
    this.PrintPhoto = this.PrintPhoto.bind(this);
    this.closeModalFE = this.closeModalFE.bind(this);
    this.buylink = this.buylink.bind(this);
    this.AddPhotoMetric = this.AddPhotoMetric.bind(this);
  }
  componentDidMount() {
    this.fetchDetails();
    this._isMounted = true;
    const { data } = this.props;
    console.log("part body", data);
    if (data) {
      //this.loadWhereUsed(data[0].PartNumber);
      this.loadWhereUsed(encodeURIComponent(data[0].PartNumber));//Added for Encoding Special Characters
    }
  }
  // componentWillUnmount() {
  //   this._isMounted = false;
  // }
  componentWillReceiveProps() {

  }

  buylink = (url, PartNumber) => {
    console.log(url);
    let urlprod;
    urlprod = BUYMETRIC(PartNumber);
    axios.get(urlprod, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((response) => {
      }, (error) => {
      })
    window.open(url, "_blank");
  }

  loadWhereUsed = (partNumber) => {
    this.setState({ loading: false });
    axios
      .get(GET_PARTLIST(partNumber, localStorage.getItem("usertype")), {
        headers: {
          ...getAuthHeader(),
        },
      })
      .then(
        (res) => {
          const data = res.data.Body;
          this.setState({ data, loading: false });
        },
        (error) => { }
      );
  };
  //added by Mousumi
  showModal = (partnumber) => {
    var photo_type = ".jpg";
    const imageName = partnumber + photo_type;
    const imageLocation = `${process.env.REACT_APP_IMAGE_PATH}`;
    //const imagePath=  imageLocation+imageName;
    const imagePath = imageLocation;
    this.CheckImageavailability(imagePath);
    this.AddPhotoMetric(partnumber);
    this.setState({ part_number: partnumber, showModal: true, imagePath });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };

  CheckImageavailability = (path) => {
    imageexists(path, function (exists) {
      if (exists) {
        localStorage.setItem("photoAvailable", "Yes");
      }
      else {
        localStorage.setItem("photoAvailable", "No");
      }
    });

  }
  AddPhotoMetric = (partnumber) => {
    let input = partnumber;
    let urlprod;
    urlprod = INSERTPHOTOMETRIC(input, localStorage.getItem("photoAvailable"));
    axios.get(urlprod, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //added my Mousumi
  PrintPhoto = () => {
    //let elem = document.getElementById("PopupId");
    let elem = document.getElementById("photoImg");
    //Changed to get the image id
    var domClone = elem.cloneNode(true);
    var $printSection = document.getElementById("printSection");
    if (!$printSection) {
      $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }
    $printSection.innerHTML = domClone.innerHTML;
    $printSection.innerHTML = elem.innerHTML;
    $printSection.appendChild(domClone);
    window.print();
    document.getElementById("printSection").remove();
  };
  //Added by Mousumi
  fetchDetails = () => {
    ;
    //let prodNumber = this.props.data[0].PartNumber;
    let prodNumber = encodeURIComponent(this.props.data[0].PartNumber);  //Added for encoding special characters
    let partNumbers = this.props.data.map(item => {
      //return item.PartNumber
      return encodeURIComponent(item.PartNumber)//Added for encoding special characters
    })
    const url = FUNCTIONAL_LIST(prodNumber, localStorage.getItem("usertype"))
    axios.post(url, partNumbers, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        let originalData = res.data.Body;
        //console.log("ori",originalData)
        let newData = this.props.data.map((item, i) => Object.assign({}, item, originalData[i]));
        //console.log("newdata",newData);
        this.setState({ modifiedData: newData })
      }, (error) => {
        console.log(error);
      })
  }
  //Added by Mousumi
  showModalFE = (partnumber) => {
    const url = FUNCTIONAL(partnumber, 'US', localStorage.getItem("usertype"))
    axios.get(url, {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        this.setState({
          showModalFE: true,
          partFEData: res.data.Body
        });
      }, (error) => {
      })
  };
  closeModalFE = () => {
    this.setState({ showModalFE: false });
  };
  //Added by Mousumi  
  handleCartChange = (event, item) => {
    //console.log("Cart ==> from props", this.props);
    const { cart } = this.props;
    //console.log("hnadle change request", event, item, [...(cart || [])]);
    if (event) {
      alert(
        `One Item with PartNumber ${item.PartNumber} has been added to the Cart`
      );
      item.qty = 1;
      const updatedCart = [...(cart || []), item];
      //console.log("add", updatedCart);
      this.props.setCarItems(updatedCart);
    } else {
      alert(
        `One Item with PartNumber ${item.PartNumber} has been removed from Cart`
      );
      const ind = getItemIndex(item.PartNumber, cart);
      if (ind > -1) {
        let newCart = cart || [];
        newCart.splice(ind, 1);
        // console.log("remove", newCart);
        this.props.setCarItems(newCart);
      }
    }
  };


  handleLoader = (value) => {
    console.log("load value", value)
    // this.setState({isLoad : value})
  }
  getImage = (event) => {
    const value = event.target.value;
    const imageLocation = `${process.env.REACT_APP_IMAGE_PATH}`;
    var text = '';
    text = text + '<h5><span class=\"badge badge-secondary"\>' + this.state.part_number + '</span></h5>';
    text = text + '<img id="photoImg" class=\"responsive"\ src=' + imageLocation + value + ".jpg" + ' alt=\"img"\/>';
    document.getElementById('mydiv').innerHTML = text;
  }


  render() {
    const { data, mobileView, cartQty } = this.props;
    const { collapsed, loading, showModal, imagePath, modifiedData, showModalFE, partFEData } = this.state;
    const columns = mobileView
      ? [
        {
          text: "#",
          dataField: '',
          headerAttrs: {
            hidden: true,
          },
          formatExtraData: (partNumber) => {
            // console.log("formatter data", a, b, c);
            return cartQty[partNumber] ? true : false;
          },
          formatter: (col, prod, index, extra) => {
            const presentInCart = extra(prod.PartNumber);
            return (
              <React.Fragment key={prod.PartNumber}>
                <b>Photo&nbsp;:&nbsp;</b>
                {prod.PartPhotoFlag ? (
                  <FontAwesomeIcon
                    icon="camera"
                    onClick={() => this.showModal(prod.PartNumber)}
                  />) : "-N/A-"}
                <br />
                <b>Part Number&nbsp;:&nbsp;</b>
                {prod.PartNumber}
                <br />
                <b>Description&nbsp;:&nbsp;</b>
                {/* {prod.EnhancedDescription} */}
                <div dangerouslySetInnerHTML={{ __html: prod.EnhancedDescription }}></div>
                <br />
                <b>CSR&nbsp;:&nbsp;</b>
                {prod.CSR ? prod.CSR : "-N/A-"}
                <br />
                <b>ROHS&nbsp;:&nbsp;</b>
                {prod.ROHS}
                <br />
                <b>FE&nbsp;:&nbsp;</b>
                {prod.FE_MATERIAL ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Functional Equivalent</Tooltip>}>
                    <ExitToAppIcon onClick={() => this.showModalFE(prod.PartNumber)} />
                  </OverlayTrigger>
                ) : "-N/A-"}
                <br />
                {Cookies.get("HP_Country") === "US" ? (
                  <div>
                    {prod.BuyLink != null ? (
                      <a
                        href="#"
                        rel="noopener noreferrer"
                        onClick={() => this.buylink(prod.BuyLink, prod.PartNumber)}
                      >
                        <img src={buy} alt="BuyLink" />
                      </a>
                    ) : ""}
                  </div>
                ) : null}
                {prod.OrderFlag === "1" ? (
                  <input type="button"
                    value={!presentInCart ? "Add to Cart" : "Remove from Cart"}
                    className="cart-button"
                    onClick={() =>
                      this.handleCartChange(!presentInCart, prod)
                    }
                  >

                  </input>
                ) : null}
              </React.Fragment>
            );
          },
        },
      ]
      : [

        {
          dataField: "PartPhotoFlag",
          text: "Photo",
          formatter: (col, row, index) =>
            col ? (
              <FontAwesomeIcon
                icon="camera"
                onClick={() => this.showModal(row.PartNumber)}
              />
            ) : null,
          headerStyle: () => {
            return { width: "2%" };
          },
        },
        {
          dataField: "OrderFlag",
          text: "Add to Cart",
          formatExtraData: (partNumber) => {
            // console.log("formatter data", a, b, c);
            return cartQty[partNumber] ? true : false;
          },
          formatter: (col, row, index, extra) => {
            // console.log("inside formatter",  row);
            console.log("inside formatter", extra)
            const letEnabled = extra(row.PartNumber);
            console.log("letEnabled", letEnabled);
            return col === "1" ? (
              <input
                type="checkbox"
                checked={letEnabled}
                onChange={(e) => this.handleCartChange(e.target.checked, row)}
              ></input>
            ) : (
              "-N/A-"
            );
          },
          headerStyle: () => {
            return { width: "2%" };
          },
        },
        {
          dataField: "PartNumber",
          text: "Part Number",
          headerStyle: () => {
            return { width: "3%" };
          },
        },
        {
          dataField: "EnhancedDescription",
          text: "Description",
          formatter: (col) => (
            <div dangerouslySetInnerHTML={{ __html: col }}></div>
          ),
          headerStyle: () => {
            return { width: "15%" };
          },
        },
        {
          dataField: "CSR",
          text: "CSR",
          headerStyle: () => {
            return { width: "3%" };
          },
        },
        {
          dataField: "ROHS",
          text: "ROHS",
          headerStyle: () => {
            return { width: "3%" };
          },
        },
        {
          dataField: "FE_MATERIAL",
          text: "FE",
          headerStyle: () => {
            return { width: "3%" };
          },
          formatter: (cell, prod, row, index) => {
            return cell ?
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Functional Equivalent</Tooltip>}
              >
                <ExitToAppIcon onClick={() => this.showModalFE(prod.PartNumber)} />
              </OverlayTrigger>
              : "-N/A-"
          }
        },
      ];

    if (!mobileView && Cookies.get("HP_Country") === "US") {
      columns.push({
        dataField: "BuyLink",
        text: "Buy Link",
        formatter: (col, row) =>
          col ? (
            <a href="#" rel="noopener noreferrer" onClick={() => this.buylink(row.BuyLink, row.PartNumber)}>
              <img src={buy} alt="BuyLink" />
            </a>
          ) : "NA",
        headerStyle: () => {
          return { width: "3%" };
        },
      });
    }

    return (
      <Loading loading={loading}>
        <React.Fragment>
          <BootstrapTable
            keyField="PartNumber"
            //data={data}
            data={modifiedData}
            columns={columns}
            striped
            pagination={data.length > 10 ? paginationFactory() : undefined}
          />
        </React.Fragment>
        <React.Fragment>
          <Modal
            size="lg"
            scrollable
            show={showModal}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body id="PopupId">
              <PartImages src={imagePath} partnumber={this.state.part_number} handleLoader={this.handleLoader.bind(this)} />
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "baseline", justifyContent: "flex-end" }}>
              <div id="imgDiv">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" for="MyImages">Select To View Additional Images</span>
                  </div>
                  <select class="custom-select" id="MyImages" onChange={this.getImage} >

                  </select>
                </div>
              </div>
              <Button id="Print" onClick={this.PrintPhoto}>
                Print
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            size="lg"
            // top
            scrollable
            show={showModalFE}
            onHide={this.closeModalFE}
          >
            <Modal.Header closeButton>
              <Modal.Title>Functional Equivalent</Modal.Title>
            </Modal.Header>
            <Modal.Body id="PopupId">
              <FunctionalEquivalent data={partFEData} />
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
        </React.Fragment>
        <div>
          <Alert
            key={0}
            onClick={() => {
              this.setState({ collapsed: !collapsed },
                () => {
                  if (this.state.collapsed && !this.state.data) {
                    this.setState({ loading: true })
                  }
                }
                // new change
                //   () => {
                //   if (this.state.collapsed && !this.state.data) {
                //     this.loadWhereUsed(this.props.data[0].PartNumber);
                //   }
                // }
              );
            }}
          >
            <div className="sty">
              {this.state.collapsed ? (
                <FontAwesomeIcon icon="minus" />
              ) : (
                <FontAwesomeIcon icon="plus" />
              )}
              &nbsp;&nbsp; Click Here To See The List Of HP Products That Part
              Number Is Used In.
            </div>
          </Alert>
          {this.state.data ? (
            <React.Fragment>
              <div className="tran">
                <Collapse in={this.state.collapsed}>
                  <div className="strip table table-striped">
                    <ProductList
                      isDescDisabled={true}
                      data={this.state.data || []}
                    />
                  </div>
                </Collapse>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </Loading>
    );
  }
}
const mapStateToProps = (state) => {
  let cartQty = {};
  state.cart.cartItems.forEach((item) => (cartQty[item.PartNumber] = item.qty));
  return {
    ...state,
    cart: state.cart.cartItems,
    cartQty,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCarItems: (newCart) => {
      dispatch(setCarItems(newCart));
    },
  };
};
//export default ViewHOC(PartList);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewHOC(PartList));
