import Cookies from 'js-cookie';
import qs from 'query-string';
import { get } from 'lodash';
import { SEARCH_TEXT, SEARCH_BY } from './constants/constants';
import { Sync } from '@material-ui/icons';
import secureLocalStorage from 'react-secure-storage';
import {tokenurlforOAuth , clientvaluesforOAuth} from './constants/APIConstants';
export function toBase64(input) {  return Buffer.from(input, 'utf-8').toString('base64')}

const token ='';

export const getCookie = () => {
    Cookies.get('HP_Country');
}

export const setCookie = (key, value) => {
    Cookies.set(key, value)
}


export const getQueryParam = (props, key) => {
    const search = get(props, 'history.location.search', '');
    const query = qs.parse(search);
    if (key) {
        return query[key];
    } else {
        return query;
    }
};

export const getURLQueryString = (searchText, searchBy) => {
    if (!searchText && !searchBy) {
        return null;
    }
    searchText=encodeURIComponent(searchText);//Added By Shreyas to encode for Part/Product with Special characters.
    let params = [];
    searchText && params.push(`${SEARCH_TEXT}=${encodeURIComponent(searchText)}`)
    searchBy && params.push(`${SEARCH_BY}=${searchBy}`)
    // console.log('23', `?${params.join('&')}`)
    return `?${params.join('&')}`
}
export const getURLQueryStringBySearch = (searchText) => {
    if (!searchText) {
        return null;
    }
    let params = [];
    searchText=encodeURIComponent(searchText);//Added By Shreyas to encode for Part/Product with Special characters.
    searchText && params.push(`${SEARCH_TEXT}=${searchText}`)
    //searchBy && params.push(`${SEARCH_BY}=${searchBy}`)
    // console.log('23', `?${params.join('&')}`)
    return `?${params.join('&')}`
}
export const getLocationObject = (searchText, searchBy) => {
    let queryString = '';
    if (!searchText && !searchBy) {
        queryString = '';
    }
    if(searchText)//Added this condition to perform encoding only if some value is passed  for search text
        {
        searchText=encodeURIComponent(searchText);//Added By Shreyas to encode for Part/Product with Special characters(Hierarchy Pop Up Data)
    }
    let params = [];
    searchText && params.push(`${SEARCH_TEXT}=${searchText}`)
    searchBy && params.push(`${SEARCH_BY}=${searchBy}`)
    queryString = `?${params.join('&')}`
    return {
        pathname: `${process.env.REACT_APP_HOME_DIRECTORY}`,
        search: queryString || undefined
    }
}

export const generateToken= () => {//Added to Generate Token after Specific Interval--Start
    //alert("Generate Token Called.!");
    const axios = require('axios');
    //const qs = require('qs');
    let data = qs.stringify({
    'grant_type': 'client_credentials' 
    });
    const encodedval = toBase64(clientvaluesforOAuth);
    let tokenurl = tokenurlforOAuth;
    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: tokenurl,
    headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': 'Basic ' + encodedval
    },
    data : data
    };

    axios.request(config)
    .then((response) => {
        localStorage.setItem("TestTokenobject",JSON.stringify(response.data));
        var expTime = new Date(new Date().getTime() + 1 * process.env.REACT_APP_TOKEN_EXP * 1000);
        const token=JSON.stringify(response.data);
        const value=JSON.parse(token);
        Cookies.set("TokenFlag","TOKEN", {
            expires: expTime // Will expire after(value is in number of sec.)
        })
        secureLocalStorage.setItem("TokenValue",value.access_token);
    })
    .catch((error) => {
        console.log(error);
    });      
}//Added to Generate Token after Specific Interval--End

export const getAuthHeader = () => {
   // console.log('aa', process.env.REACT_APP_API_USERNAME, process.env.REACT_APP_API_PASSWORD)
    //const token = Buffer.from(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`).toString('base64')
    if(Cookies.get("TokenFlag")){
        const token=secureLocalStorage.getItem("TokenValue");
        //alert("valid cookie msg :from auth function");
        return {
            'Authorization': `Bearer ${token}`,
        }
    }
    else{
        generateToken();
        //alert("Cookie is Undefined");
        const token1=secureLocalStorage.getItem("TokenValue");
        //alert("valid cookie msg :from auth function");
        return {
            'Authorization': `Bearer ${token1}`,
        }
    }
}

export const getAuthHeaderUID = () => {
     const token = Buffer.from(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`).toString('base64')
     return {
        'Authorization': `Basic ${token}`,
        'content-type': 'application/x-www-form-urlencoded',
     }
 }

 export const bodyParametersUID = (code) => {
    return {
        'grant_type': 'authorization_code',
        'code': `${code}`,
    }
 };