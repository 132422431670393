//https://github.com/iannbing/react-simple-tree-menu - Reference for TreeView
import React from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';// Added By Shreyas for local storage security.
import Loading from './Loading';
import { GET_HIERARCHY,GETHIERARCHYPRODUCT, GETGLBURL } from './constants/APIConstants';
import { getAuthHeader } from './Util';
import { ViewHOC } from "./ViewHOC";
import TreeMenu from 'react-simple-tree-menu'
import "./bootstrap.min.css";
import "./design.css";
import './main.css';
import Navbar from "./navbar";
import Login from "./login";
//import ModalPopup from './ModalPopup';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap'; 
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next"; 
//import { SEARCH_MODES } from "./constants/constants";
import { getLocationObject } from "./Util";

class Hierarchy extends React.Component {
  state = {
    countryLoading: true,
    dataprod: null,
    showModalPopup: false,
    prodparents: null,
    Level1: null,
    Level2: null,
    Level3: null,
    Level4: null,
    Level5: null,
    Level6: null,
    User : '',
  };
  
  componentDidMount() {
    const {countryLoading} = true;
    axios.get(GET_HIERARCHY(localStorage.getItem("usertype")), {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        const dt = res.data.Body;
        this.setState({ data: res.data.Body, countryLoading: false })
      }, (error) => {
        this.setState({ data: null, countryLoading: false })
      })

  }
  
  removeCookie = (e) => {
    e.preventDefault();
    secureLocalStorage.removeItem("loggeduser");
    this.setState({ User: null });
    alert("LogOut Success!");
    window.location.href = "/";
  }

  isShowPopup = (status) => {  
    this.setState({ showModalPopup: status });  
  }; 
  isShowModal = (status) => {  
    this.handleClose();  
    this.setState({ showModal: status });  
  }  

  handleClose = () => {  
    this.isShowPopup(false);  
        //window.location.href = '/PartSurfer/Hierarchy';
  }
  
  proddatasplit = (dt) => {  
    let res = dt || {};
    var array = res.parentItems.split("->");
    if(array != null)
    {
      if(array.length == 6)
      {
        this.setState({Level1: array[0], Level2: array[1], Level3: array[2], Level4: array[3], Level5: array[4], Level6: array[5]});
      }
      else
      {
        this.setState({Level1: array[0], Level2: array[1], Level3: array[2], Level4: array[3], Level5: array[4], Level6: null});
      }      
    }
        this.setState({
          dataprod: res.hierarchyProds,
          prodparents: res.parentItems,
        });  
  } 

  loadData = (key,label, props) => {
    this.setState({ countryLoading: true })
    var array = key.split("/");
    const { hasNodes } = props;
    let urlprod;
    let arraycount;
    arraycount = array.length - 1;
    //Added for Text Click by Shreyas Nene to get value of level.
    //---Start Here
    const {level}=props;
    //Added for Text Click by Shreyas Nene to get value of level---End Here
    urlprod = GETHIERARCHYPRODUCT(array[arraycount], localStorage.getItem("usertype"));
    var dt1 = this.state.data;
    if (!hasNodes) {
      axios.get(urlprod, {
        headers: {
          ...getAuthHeader()
        }
      })
        .then((response) => {
          this.proddatasplit(response.data.Body);
          this.setState({ countryLoading: false })
          this.isShowPopup(true);
        }, (error) => {
          this.setState({ countryLoading: false })
        })
    }
    else{
        this.setState({ countryLoading: false })
        // Added by Shreyas Nene to enable click event on TreeMenu Text Click-------Start here        
       var list=document.getElementsByClassName("rstm-tree-item");
       for (let i = 0; i < list.length; i++){
        if(list[i].textContent.substring(1)==label){
          if(list[i].classList.contains('rstm-tree-item-level'+level))
          list[i].querySelector(".rstm-toggle-icon-symbol").click();
        }
       };        
      //Added by Shreyas Nene to enable click event on TreeMenu Text Click-------End here

    }
  }


  render() {
    const { countryLoading, data, dataprod } = this.state;
    const {mobileView } = this.props;
        const columns = mobileView
        ? [
          {
            text: "#",
            dataField: '',
            headerAttrs: {
              hidden: true,
            },
            formatter: (col, prod, index) => {
              return (
                <React.Fragment key={prod.product_number}>
                  <b>HP Model Number&nbsp;:&nbsp;</b>
                        <Link style={{color: "#007bff"}} to={getLocationObject(prod.product_number)} >
                          {prod.product_number}
                        </Link>
                  <br />
                  <b>Product Name&nbsp;:&nbsp;</b>
                  {prod.product_name}
                  <br />
                  
                </React.Fragment>
              );
            },
          },
        ]
      : [
          {
            dataField: "product_number",
            text: "HP Model Number",
            formatter: (col) => (
                <div>
                    <Link style={{color: "#007bff"}} to={getLocationObject(col)} >
                        {col}
                    </Link>
              </div>
            ),
            headerStyle: () => {
              return { width: "3%" };
            },
          },
          {
            dataField: "product_name",
            text: "Product Name",
            headerStyle: () => {
              return { width: "15%" };
            },
          },
        ];
        const{Level1,Level2, Level3,Level4,Level5,Level6,User} = this.state;
        const styleObject = {
          "float" : "right",
          "color" : "#003366",
          "width" : "100%"
          }
    return (      
      <React.Fragment>
        <Navbar />
        <div style={{ marginRight: "10px"}}>
          <br/>
          <Login />
          <br/>
        </div>
        <div style={{ backgroundColor: "white" }} className="container-fluid">
          <Loading loading={countryLoading } >          
          <div className="row">
              <div className="col-md-9">
                &nbsp;&nbsp;&nbsp;&nbsp;<span id="ctl00_SubTitle">Hierarchy Menu:</span>
              </div>
          </div>
          <div><br/></div>
          
          <div className="container">
              
                  <TreeMenu data={data} debounceTime={500}
                  disableKeyboard={false}
                  hasSearch={false}
                  onClickItem={({ key, label, ...props }) => {
                      this.loadData(key,label,props);
                    }}
                  resetOpenNodesOnDataUpdate={true}/>
              
          </div>
          { dataprod &&
              <Modal show={this.state.showModalPopup} onHide={this.handleClose} onPopupClose={this.isShowPopup} 
                    size="lg"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered  >  
                    <Modal.Header closeButton>  
                        <Modal.Title id="sign-in-title">  
                            PartSurfer Hierarchy Products  
                         </Modal.Title>  
                    </Modal.Header>  
                    <Modal.Body>  
                            <React.Fragment>
                              <div style={{fontWeight: "bold"}}>
                                {/* {this.state.prodparents} */}
                                {Level1} <i class="fas fa-arrow-right"></i> {Level2} <i class="fas fa-arrow-right"></i> {Level3} <i class="fas fa-arrow-right"></i> {Level4} <i class="fas fa-arrow-right"></i> {Level5} <i class="fas fa-arrow-right"></i> {Level6}
                                <br/>
                                <br/>
                              </div>
                            </React.Fragment>
                            <React.Fragment>
                                <BootstrapTable
                                    keyField="PartNumber"
                                    data={dataprod}
                                    columns={columns}
                                    striped
                                    pagination={dataprod.length > 10 ? paginationFactory() : undefined}
                                />
                            </React.Fragment>
                        <hr />  
                        <div className="signUp">  
                            <p>Go back to Hierarchy Menu?  <button type="button" className="apply btn btn-primary" onClick={() => this.isShowModal(true)}> Close</button></p>  
                        </div>  
                    </Modal.Body> 
                </Modal >  
          }
          </Loading>
          </div>
          
            <div>
              <br />
            <br />
            <br />
        <div className="col-md-12 text-md-center">
          <Link style={{ color: "#003366" }}  to="/PartSurfer/ContactUS">
            <u>Feedback to HP PartSurfer</u>
          </Link>
        </div>
        <br />
        </div>
        
      </React.Fragment>
       
    );
  }
}

export default ViewHOC(Hierarchy)
