import React from "react";
import { ViewHOC } from "./ViewHOC";
import "./bootstrap.min.css";
import "./design.css";
import './main.css';
import Navbar from "./navbar";
import Login from "./login";
import Loading from './Loading';
import { getURLQueryString, getAuthHeader } from './Util';
import { GETSBOM } from './constants/APIConstants';
import axios from 'axios';
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next"; 
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import "./design.css";
import secureLocalStorage from "react-secure-storage";
class Sbom extends React.Component {
    constructor(props) {
      super(props);    
      this.state = {
        modalnumber: '', 
        problemdesc: '', 
      };
      this.state = {countryLoading: true, countryerror: '', ProdNum: ''}
    }
    
    componentDidMount() {
        let productNum = this.props.match.params.value;
        let urlprod;
        urlprod = GETSBOM(productNum,'US',localStorage.getItem("usertype"));
        axios.get(urlprod, {
            headers: {
            ...getAuthHeader()
            }
      })
        .then((res) => {
            this.setState({ data: res.data.Body, countryLoading: false, ProdNum: productNum })
        }, (error) => {
            this.setState({ data: null, countryLoading: false, ProdNum: productNum })
        })
  
    }
  
    render() {
        const { countryLoading, data, ProdNum } = this.state;
        const {mobileView } = this.props;
            const columns = mobileView
            ? [
              {
                text: "#",
                dataField: '',
                headerAttrs: {
                  hidden: true,
                },
                formatter: (col, prod, index) => {
                  return (
                    <React.Fragment key={prod.PartNumber}>
                      <b>Part Number&nbsp;:&nbsp;</b>
                        {prod.PartNumber}
                      <br />
                      <b>Description&nbsp;:&nbsp;</b>
                      {prod.PartName}
                      <br />
                      <b>CSR&nbsp;:&nbsp;</b>
                      {prod.CsrFlag}
                      <br />
                      <b>Tech Courier&nbsp;:&nbsp;</b>
                      {prod.TechCourier}
                      <br />
                      <b>ROHS&nbsp;:&nbsp;</b>
                      {prod.RohsCompliant}
                      <br />
                    </React.Fragment>
                  );
                },
              },
            ]
          : [
              {
                dataField: "PartNumber",
                text: "Part Number",                
                headerStyle: () => {
                  return { width: "3%" };
                },
              },
              {
                dataField: "PartName",
                text: "Description",
                headerStyle: () => {
                  return { width: "15%" };
                },
              },
              {
                dataField: "CsrFlag",
                text: "CSR",
                headerStyle: () => {
                  return { width: "5%" };
                },
              },
              secureLocalStorage.getItem("loggeduser") == null ?{
                dataField: "TechCourier",
                text: "Tech Courier",
                hidden: true,
                headerStyle: () => {
                  return { width: "5%" };
                },
              }: {
                dataField: "TechCourier",
                text: "Tech Courier",
                headerStyle: () => {
                  return { width: "5%" };
                },
              },
              {
                dataField: "RohsCompliant",
                text: "ROHS",
                headerStyle: () => {
                  return { width: "8%" };
                },
              },
            ];
      return (
        
        <React.Fragment>          
        <Loading loading={countryLoading } >
        <div style={{ backgroundColor: "white" }} className="container-fluid">
            <Navbar />            
            <div style={{ marginRight: "10px"}}>
              <br/>
              <Login />
              <br/>
            </div>           
            <div className="container">
                {data &&
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            &nbsp;&nbsp;&nbsp;<span id="ctl00_SubTitle">SBOM for Products: {ProdNum}</span>
                         
                           
                        </div>
                    </div>
                    <div className="btn-back-to-previous" >
                      <Link rel="noopener noreferrer" className="apply btn btn-primary" to={`../../?searchtext=${ProdNum}`}>
                               Back
                            </Link>
                           
                       </div>
                    <div><br/></div>
                                <BootstrapTable
                                    keyField="PartNumber"
                                    data={data}
                                    columns={columns}
                                    striped
                                />
                </React.Fragment>
                }
                { !data && 
                    <div> There is no SBOM for Product: {ProdNum}</div>
                }
            </div>
        </div>
        </Loading>
        </React.Fragment>
      );
    }
  }
    
    export default ViewHOC(Sbom);