import Cookies from "js-cookie";

export const CART_KEY = "PS_CART";

export const getStoredCart = () => {
  return Cookies.getJSON(CART_KEY) || [];
};

export const setStoredCart = (cartDetails) => {
  Cookies.set(CART_KEY, JSON.stringify(cartDetails));
  //Cookies.set(CART_KEY, cartDetails);
  sessionStorage.setItem(CART_KEY, cartDetails);
};

export const getItemIndex = (partNumber, cartList) => {
  // console.log("inex", partNumber, cartList);
  if (partNumber) {
    let items = [...(cartList || [])];
    let index;
    items.forEach((item, i) => {
      if (item.PartNumber === partNumber) index = i;
    });
    return index;
  }
};
