import zIndex from "@material-ui/core/styles/zIndex";
import React,{useEffect} from "react";

class GoToTop extends React.Component{

    componentDidMount() {
     window.addEventListener("scroll",this.listenToScroll)
      }

      listenToScroll=()=>{
        if(document.body.scrollTop>1000 || document.documentElement.scrollTop >1000){
            document.getElementById("my-btn").style.display='block';
        }
        else
        {
            document.getElementById("my-btn").style.display='none'; 
        }
    }

    goToBtn=()=>{
               window.scroll({top:0 ,left:0,behavior:"smooth"})
        }

    render(){
        const mystyle= {
            display: "none", 
    position: "fixed", 
    bottom: "20px", 
    right: "30px", 
    zIndex: "99", 
    border: "none", 
    outline: "none", 
    backgroundcolor: "red", 
    color: "white", 
    cursor: "pointer", 
    padding: "15px", 
    borderradius: "10px", 
    fontsize: "18px" 
        }

return(
   <>
<input type="button" class="btn-primary rounded-pill " id ="my-btn" style={mystyle}  onClick={this.goToBtn} value="Go to top">
    </input> 
    </>
);
    }
}
 export default GoToTop;