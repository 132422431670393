export const WIDTH_THRESHOULD = 590;

export const SEARCH_TEXT = 'searchtext';
export const SEARCH_BY = 'searchby';
export const Code_Url = 'code';

export const SEARCH_MODES = {
    PART: 'part',
    PRODUCT: 'product',
    FUNCTIONAL_EQUIVALENT: 'fe',
    SEARIAL_NUMBER: 'sn',
    WILD_CHAR: 'wc',
    SERIAL_WP: 'swp'
}

export const PART_PHOTO = {
    PRESENT: 'Yes',
    NOT_PRESENT: 'No',
    NOT_APPLICABLE: 'ALL'
}

export const PART_FE = {
    PRESENT: 'Yes',
    NOT_PRESENT: 'No',
    NOT_APPLICABLE: 'ALL'
}
export const DocHeight=document.body.scrollHeight;

export const URLHeader = 'PartSurfer';

export const MailIDS = 'shreyas.shrikant.nene@hp.com;santhosh.shanmugam@hp.com;rajeev.sharma@hp.com;purnima.kandukuri@hp.com;andrew.cutlip@hp.com;athar.mumtaz1@hp.com';