import React from "react";
import secureLocalStorage from 'react-secure-storage';// Added By Shreyas for local storage security.
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { ViewHOC } from "./ViewHOC";

class ComponentBOM extends React.Component {
  constructor() {
    super();
    this.state = {
      Ishide: false,
    };
  }

  isValid = () => {
    const { data } = this.props;
    return (
      data.length !== 1 &&
      data.filter((row) => row.parent_part_number !== "-N/A-").length > 0
    );
  };

  render() {
    const { data, mobileView } = this.props;
    const {Ishide} = this.state;

    const columns = mobileView
      ? [
          {
            text: "#",
            dataField:'',
            headerAttrs: {
              hidden: true,
            },
            formatter: (col, prod, index) => {
              return (
                <React.Fragment key={prod.part_number}>
                  {this.isValid() ? (
                    <React.Fragment>
                      <b>Parent Part Number&nbsp;:&nbsp;</b>
                      {prod.parent_part_number}
                      <br />
                      <b>Assembly Part Number&nbsp;:&nbsp;</b>
                      {prod.part_number}
                      <br />
                      <b>Part Description&nbsp;:&nbsp;</b>
                      {prod.part_description}
                      <br />
                      {secureLocalStorage.getItem("loggeduser") == null ?  "": <b>Part Serial Number&nbsp;:&nbsp;</b>  }
                      {secureLocalStorage.getItem("loggeduser") == null ?  "": prod.part_serialno}
                      {secureLocalStorage.getItem("loggeduser") == null ?  "":<br />}
                      <b>QTY &nbsp;:&nbsp;</b>
                      {prod.part_quantity}
                      <br />
                    </React.Fragment>
                  ) : (
                    <div> No additional information found in PartSurfer.</div>
                  )}
                </React.Fragment>
              );
            },
          },
        ]
      : [
          {
            dataField: "parent_part_number",
            text: "Parent Part Number",
          },
          {
            dataField: "part_number",
            text: "Assembly Part Number",
          },
          {
            dataField: "part_description",
            text: "Part Description",
          },
          secureLocalStorage.getItem("loggeduser") == null ?{
            dataField: "part_serialno",
            text: "Part Serial Number",
            hidden: true,
          }: {
            dataField: "part_serialno",
            text: "Part Serial Number",
          },
          {
            dataField: "part_quantity",
            text: "QTY",
          },
        ];

    return (
          <React.Fragment>
            {this.isValid() ? (
              <BootstrapTable
                keyField="part_number"
                striped
                data={(data || []).map((rec, ind) => ({ ...rec, key: ind }))}
                columns={columns}
                
              />
            ) : (
              <div> No additional information found in PartSurfer.</div>
            )}
            {/* pagination={data.length > 10 ? paginationFactory() : undefined} */}
         </React.Fragment>
    );
  }
}

export default ViewHOC(ComponentBOM);
