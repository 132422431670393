import React from 'react'
import { useEffect } from "react";
import imageexists from 'image-exists';
import { text } from '@fortawesome/fontawesome-svg-core';

export default function PartImages({ src, partnumber, handleLoader }) {

  const getImage = (event) => {
    const value = event.target.value;
    text = '';
    text = text + '<h5><span class=\"badge badge-secondary"\>' + partnumber + '</span></h5>';
    text = text + '<img id="photoImg" class=\"responsive"\ src=' + src + value + ".jpg" + ' alt=\"img"\/>';
    document.getElementById('mydiv').innerHTML = text;
  }

  const loadComplete = () => {
    //handleLoader(false);
  }

  let content = []; var text = '';


  useEffect(() => {

    var src2;
    var src1 = src;
    var text = '';


    src1 = src + partnumber + ".jpg";
    text = text + '<h5><span class=\"badge badge-secondary"\>' + partnumber + '</span></h5>';
    text = text + '<img id="photoImg" class=\"responsive"\ src=' + src1 + ' alt=\"img"\/>';
    document.getElementById('mydiv').innerHTML = text;
    document.getElementById('imgDiv').style.display = 'none';
    var x = document.getElementById("MyImages");
    var option = document.createElement("option");
    option.text = partnumber;
    x.add(option);
    for (let i = 1; i < 20; i++) {
      src2 = src + partnumber + "-" + i + ".jpg";
      imageexists(src2, function (exists) {
        if (exists) {
          var x = document.getElementById("MyImages");
          var option = document.createElement("option");
          option.text = partnumber + "-" + i;
          x.add(option);
          document.getElementById('imgDiv').style.display = 'block';
        }
        else {
        }
      });
    }


  }, []);

  return (

    <div id="mydiv" className='responsive'>
    Loading Image Please Wait....
    </div>

  )
}
