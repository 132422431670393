import React from "react";
import { ViewHOC } from "./ViewHOC";
import "./bootstrap.min.css";
import "./design.css";
import './main.css';
import Navbar from "./navbar";
import Loading from './Loading';
import { getURLQueryString, getAuthHeader } from './Util';
import { GET_COUNTRIES,INSERTFEEDBACK } from './constants/APIConstants';
import axios from 'axios';

class NewRequest extends React.Component {
  constructor(props) {
    super(props);    this.state = {
      modalnumber: '', 
      problemdesc: '', 
      troubleshootingsteps: '', 
      installation: '', 
      nameofcust: '', 
      email: '', 
      phonenumber: '', 
      country : '',
      SelectValue: "Please Select a Country"
    };
    this.state = {countryLoading: true, countryerror: ''}
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.setState({SelectValue : "Please Select a Country"})
  }
  handleDropdownChange(e) {
    this.setState({ SelectValue: e.target.value });
  }
  componentDidMount() {
    getURLQueryString()
    axios.get(GET_COUNTRIES(localStorage.getItem("usertype")), {
      headers: {
        ...getAuthHeader()
      }
    })
      .then((res) => {
        const data = res.data.Body;
        let dat = data.map((con, index) => {
          return { key: con.CountryCode, value: con.CountryName }
        })
        this.setState({ data: dat, countryLoading: false })
        this.setState({SelectValue : "Please Select a Country"})
      }, (error) => {
        // console.log(error);
        this.setState({ countryLoading: false })
        this.setState({SelectValue : "Please Select a Country"})
      })

  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }

  emailvalidation(value) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(value)) {
        return false;        
      }
      else
      {
        return true;
      }
  }

  phonevalidation(value) {
    var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(value)) {
        return false;        
      }
      else
      {
        return true;
      }
  }

  handleSubmit(event) {
    this.setState({ countryLoading: true })
    const { modalnumber, problemdesc, troubleshootingsteps, installation, nameofcust, email, phonenumber, SelectValue } = this.state;

    if(!SelectValue.includes("Select") )
    {
      if(this.emailvalidation(email))
      {
        let input = modalnumber + "$$" + problemdesc + "$$" + troubleshootingsteps + "$$" + installation + "$$" + nameofcust + "$$" + email + "$$" + phonenumber + "$$" + SelectValue;
        // let request = input.replaceAll("/", "@@");
        // request = request.replaceAll("#","&&&");
        // request = request.replaceAll("?","%%");
        console.log(input);
        const content = { text: input };
        let urlprod;
        urlprod = INSERTFEEDBACK();
        axios.post(urlprod, content, {
            headers: {
              ...getAuthHeader()
            }
          })
            .then((response) => {
              if(response.data == true)
              {
                alert("Confirmation of your submission has also been mailed to the email address provided by you");
              }
              else
              {
                alert("Some Error Occured on submitting Request!");
              }
              this.setState({ countryLoading: false, SelectValue : "Please Select a Country", countryerror: "" })
            }, (error) => {
              this.setState({ countryLoading: false, SelectValue : "Please Select a Country", countryerror: "" })
        })
      }
      else{
        this.setState({countryLoading: false,  countryerror: "Please enter valid email or phone number!" })
      }
    }
    else
    {
      this.setState({countryLoading: false, SelectValue : "Please Select a Country", countryerror: "Please select country!" })
    }
    event.preventDefault();
  }

  render() {
    const { countryLoading } = this.state;
    return (
      
      <React.Fragment>
        
        <Loading loading={countryLoading } >
        <div style={{ backgroundColor: "white" }} className="container-fluid">
          <Navbar />
          <div><br/>
              <div>
                      <div className="alert alert-success" role="alert">
                          <h4 className="alert-heading">
                            E-mail form:-
                          </h4>
                          <p>
                            The information we are requesting will be used solely to fulfill your e-mail support request and does not register you as an HP customer.
                            <br/>
                            Most e-mail responses can be expected within 1 business day.
                          </p>
                        </div>
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-9">
                &nbsp;&nbsp;&nbsp;&nbsp;<span id="ctl00_SubTitle">Submit Request:</span>
              </div>
          </div> */}
          <div><br/></div>
          
          <div className="container">
              <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-10">
                  Name or model number of your HP product:
                </div>
              </div>
                <div className="input-group">
                    <input type="text" className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.modalnumber} name="modalnumber" onChange={this.handleChange} required />
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-10">
                    Please provide a detailed description of the exact problem:
                    </div>
                </div>
                <div className="input-group">
                    <textarea  className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.problemdesc} name="problemdesc" onChange={this.handleChange} rows={5} cols={5} required />
                </div>
                {/* <br/>
                <div className="row">
                    <div className="col-md-10">
                    Please list previous troubleshooting steps, or information that can help HP assist you:
                    </div>
                </div>
                <div className="input-group">
                    <textarea  className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.troubleshootingsteps} name="troubleshootingsteps" onChange={this.handleChange} />
                </div> */}
                {/* <br/>
                <div className="row">
                    <div className="col-md-10">
                    Has anything changed since the unit functioned properly (installation of SW, settings, cabling, etc.).
                    </div>
                </div>
                <div className="input-group">
                    <textarea  className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.installation} name="installation" onChange={this.handleChange} />
                </div> */}
                <br/>
              <div className="row">
                <div className="col-md-10">
                  Name (Firstname & Lastname):
                </div>
              </div>
                <div className="input-group">
                    <input type="text" className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.nameofcust} name="nameofcust" onChange={this.handleChange} required />
                </div>
              <br/>
              <div className="row">
                <div className="col-md-10">
                  Email Address:
                </div>
              </div>
                <div className="input-group">
                    <input type="text" className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.email} name="email" onChange={this.handleChange} required />
                </div>
              {/* <br/>
              <div className="row">
                <div className="col-md-10">
                  Phone Number:
                </div>
              </div>
                <div className="input-group">
                    <input type="text" className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" value={this.state.phonenumber} name="phonenumber" onChange={this.handleChange} required />
                </div> */}
                <br/>
                <div className="row">
                <div className="col-md-10">
                  Country/Region:
                </div>
              </div>
                <div className="input-group">
                  <select name="productsnrlists" className="form-control col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-8" onChange={this.handleDropdownChange} >  
                          <option>Please Select a Country</option>  
                          {(this.state.data || []).map((country, index) => {  
                                  return <option key={index} value={country.key}>{country.value}</option>;  
                          })}  
                  </select>                  
                </div>
                <div>
                  <br/>
                  <p>
                    <b>
                    Important Note: You should receive an automated acknowledgment from HP within 1 hour. If you do not receive one, it is possible that the security settings (SPAM filtering) are preventing HP messages from being delivered to your inbox. Please check for these messages in another folder such as junk, bulk, unknown, or trash
                    </b>
                  </p>
                </div>
                <div>
                  <div>
                  {this.state.countryerror && <div style={{color: "#E9573F"}}>{this.state.countryerror}</div>
                  }
                </div>
                <div>
                <input type="submit"  value="Submit" className="apply btn btn-primary" />
                </div>
                <br/>
                <br/>
                </div>
              </form>
          </div>
          </div>
        </Loading>
      </React.Fragment>
    );
  }
}
  
  export default ViewHOC(NewRequest);