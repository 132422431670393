import React from 'react'
import loader from '../page_preload.gif'

export default function Loading({ loading = false, children }) {
    return (
        // <div>
        //     {loading && <div className="nested-loading" style={{
        //         position: 'fixed', left: '50%', top: '50%', zIndex: 999, opacity: 1
        //     }}>
        //         <img src={loader} alt="loading..." /></div>}
        //     <div className={loading ? 'loader' : undefined} style={{ pointerEvents: loading ? 'none' : 'all' }}>
        //         {children}
        //     </div >
        // </div>
        <div style={{ position: 'relative', boxSizing: 'border-box' }}>
            {loading && <div>
                <div style={{
                    position: 'absolute',
                    height: '100%',
                    zIndex: 999,
                    display: 'block', width: '100%', textAlign: 'center'
                    , top: '50%'
                }}>
                    <img src={loader} alt="loading..." />
                </div>
            </div>}
            {loading ? <div style={{ opacity: '0.2' }}>
                {children}
            </div> : children}
        </div>
    )
}
