import zIndex from "@material-ui/core/styles/zIndex";
import React,{useEffect} from "react";
import secureLocalStorage from "react-secure-storage";//Added By Shreyas for Local Storage Security.
import {RESPONSEURLUID,tokencallurl,UIDClientID} from './constants/APIConstants';
import { setCookie, getQueryParam, getURLQueryString, getAuthHeader, getAuthHeaderUID, bodyParametersUID } from './Util';
import axios from 'axios';
import { Buffer } from 'buffer'
import { Code } from "@material-ui/icons";
import { GETUIDRESPONSE,GET_COUNTRIES } from './constants/APIConstants';
import Loading from './UIDLoader';
export function toBase64(input) {  return Buffer.from(input, 'utf-8').toString('base64')}
export function fromBase64(encoded) {  return Buffer.from(encoded, 'base64').toString('utf8')}


class CallBack extends React.Component{

    componentDidMount()
    {
        let urltkn;
        urltkn = tokencallurl;
        this.setState({loading: true})
        const queryParameters = new URLSearchParams(window.location.search)
        const Code = queryParameters.get("code")
        const encodedval = toBase64(UIDClientID);
        var access_token="";
        var axios = require('axios');
        var config = {
          method: 'post',
          //url: 'https://login-itg.external.hp.com/as/token.oauth2?redirect_uri=http://localhost:3000/PartSurfer/CallBack&code='+Code+'&grant_type=authorization_code',
          url: 'https://'+urltkn+'&code='+Code+'&grant_type=authorization_code',
          headers: { 
            'Authorization': 'Basic '+ encodedval, 
            'Content-Type': 'application/x-www-form-urlencoded', 
            'Cookie': 'HPIUID=jyzwhPBEf53HrNc0Zi8mGS'
          }
        };

        axios(config)
        .then(function (response) {  
          //debugger;        
          var obj=JSON.stringify(response.data);
          console.log(obj);
          let access_token_data = JSON.parse(obj);
          console.log(access_token_data);
          access_token=access_token_data.access_token;   
        })
        .catch(function (error) {   
         //debugger;       
          console.log(error);
     }).finally(() => {      
          debugger;    
            const content = { text: access_token };
            let url;
            url = GETUIDRESPONSE();
            axios.post(url,content, {
              headers: {
                ...getAuthHeader()
              }
            })
              .then((res) => {
                var email = res;
                this.setState({ data: res.data.Body })
                if(res.data=="UID_Failed")
                {                     
                    secureLocalStorage.setItem("loggeduser",res.data); 
                    window.location.href = "/";
                }
                else
                {
                  secureLocalStorage.setItem("loggeduser",res.data);
                  this.setState({ User: res.data });             
                  window.location.href = "/?Code="+res.data;

                }
                this.setState({ loading: false });
              }
              , (error) =>
              {
                console.log(error);                    
              }) 
      });

    }



    render(){

return(
  <React.Fragment>
  <Loading loading={Loading} ></Loading>
  <div>
            <br />
            
            <div  className="row vh-100">
              <div className="col-sm-6">
              </div>
              <div className="col-sm-6">
               
                </div></div></div>
  
  
  </React.Fragment>
);
    }
}
export default CallBack;