import React from 'react';
import { WIDTH_THRESHOULD } from './constants/constants';

export const ViewHOC = WrappedComponent => {
    // ...and returns another component...
    const WrappedComponentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return class extends React.Component {
        static displayName = `WithView(${WrappedComponentDisplayName})`;

        state= {}

        componentDidMount() {
           
            window.addEventListener("resize", this.resize);
            this.resize()
        }
  
        componentWillUnmount() {
            // fix Warning: Can't perform a React state update on an unmounted component
            this.setState = (state,callback)=>{
                return;
            };
        }
        

        resize = () => {
            this.setState({ isMobile: window.innerWidth < WIDTH_THRESHOULD })
        }

        render() {
            return (
                <WrappedComponent 
                    mobileView={this.state.isMobile}
                    {...this.props} />
            );
        }
    }
}